import type { OverviewBarge } from '../../../Domain/Barge'
import type { HubLikeId, LaneId } from '../../../generated/graphql'

export enum NominationStage {
  LaneSelection,
  VesselSelection,
  DepartureTimeSelection,
  PoolFiltersSelection,
  TowParametersSelection,
}

export type CurrentStage = {
  stage: NominationStage
}

export type LaneSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.LaneSelection
}

export type VesselSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.VesselSelection
}

export type DepartureTimeSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.DepartureTimeSelection
}

export type PoolFiltersSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.PoolFiltersSelection
}

export type TowParametersSelectionStage = Omit<CurrentStage, 'stage'> & {
  stage: NominationStage.TowParametersSelection
}

export const isLaneSelectionStage = (cs: CurrentStage) => cs.stage === NominationStage.LaneSelection
export const isVesselSelectionStage = (cs: CurrentStage) => cs.stage === NominationStage.VesselSelection
export const isDepartureTimeSelectionStage = (cs: CurrentStage) => cs.stage === NominationStage.DepartureTimeSelection
export const isPoolFiltersSelectionStage = (cs: CurrentStage) => cs.stage === NominationStage.PoolFiltersSelection
export const isTowParametersSelectionStage = (cs: CurrentStage) => cs.stage === NominationStage.TowParametersSelection

export type StageTransition = {
  next: NominationStage | null
  previous: NominationStage | null
}

export type StageChangeActions = {
  nextStage: (() => void) | null
  previousStage: (() => void) | null
}

export type StageSelectionActions = {
  self: () => void
}

export type StageActions = StageSelectionActions & StageChangeActions

export type StageData<F, S, I> = {
  isSelected: boolean
  isVisible?: boolean
  form: F
  summary: S
  initParameters?: I
  actions: StageActions
  onFocus?: () => void
  onBlur?: () => void
}

export type LaneSelectionFormValues = {
  laneId: LaneId | undefined
  origin: HubLikeId | undefined
  destination: HubLikeId | undefined
}

export type LaneSelectionSummaryValues = {
  description: string
}

export type StageFormData<F> = {
  data: F
  onChange: (data: F) => void
}

export type StageSummaryData<S> = S

export type BargePoolViewModel = {
  lane: LaneId | undefined
  barges: OverviewBarge[]
  isFetching: boolean
}

export type NominationFormViewModel = {
  currentStage: CurrentStage
  stages: Record<NominationStage, StageData<StageFormData<any>, StageSummaryData<any>, any>>
  bargePool: BargePoolViewModel
}

export type VesselSelectionFormValues = {
  boatId?: string
  hasTurnboat: boolean
}

export type VesselSelectionSummaryValues = {
  description: string
}
