import React, { type FC, type PropsWithChildren } from 'react'

import styles from './styles.module.scss'

import type { StageSelectionActions } from './types'

type SummaryBlockProps = StageSelectionActions & {
  title: string
}

const SummaryBlock: FC<PropsWithChildren<SummaryBlockProps>> = ({ title, self, children }) => {
  return (
    <div onClick={self} className={styles.summaryBlock}>
      <div className={styles.blockTitle}>{title}</div>
      <div className={styles.summaryContainer}>{children}</div>
    </div>
  )
}

export default SummaryBlock
