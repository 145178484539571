import { useCallback, useEffect, useMemo, useState } from 'react'

import { Formik, Form, FormikHelpers } from 'formik'

import { LaneFilters } from '../../../components/Header/LaneFilters/LaneFilters'
import {
  LastUpdateIndicator,
  PrimaryMenu,
  PrimaryMenuRight,
  ToggleFilterScreenButton,
} from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { groupOverviewBarges } from '../../../components/Table/bargeGrouping'
import { GoalNominatedBargesTable } from '../../../components/Table/NominatedBargesTable'
import { ColumnKey, columnKeys, columns } from '../../../components/Table/SummaryBargesTable'
import { fromBargesQuery, isEmpty, OverviewBarge } from '../../../Domain/Barge'
import { Filter, searchParamsToFilter } from '../../../Domain/BargeFilters'
import { getExcludedBargeTypes } from '../../../Domain/BargeType'
import { isGoalId } from '../../../Domain/Goal'
import { shortDropLanes } from '../../../Domain/Lane'
import { showRiverLocation } from '../../../Domain/River'
import { excludePlacedToLoadStatuses } from '../../../Domain/Trip'
import {
  CreateBargeNominationMutation,
  GoalId,
  HubLikeId,
  LaneId,
  useCreateBargeNominationMutation,
  useLaneBargesQuery,
} from '../../../generated/graphql'
import { Sortable, SortingState, useSorting } from '../../../lib/Column'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { Layout } from '../../../ui/Layout/Layout'
import { NominationListItemHeader } from '../../../ui/NominationList/NominationListItem'
import { LoadingSpinner, Spinner } from '../../../ui/Spinner/Spinner'
import { FilterColunm } from '../../../ui/Table/FilterColumn/FilterColumn'
import { SortableColumn } from '../../../ui/Table/SortColumn/SortColumn'
import { TB, TBHead, TBBody, TBR, HeaderCell } from '../../../ui/Table/Table'
import { TableCell } from '../../../ui/Table/TableCell'
import { errorToast } from '../../../ui/Toast/Toast'
import { isoZonedDateTime } from '../../../utils/date'

import { CombinedBargesTable } from './CombinedBargesTable'
import styles from './GoalsDiff.module.scss'

type NominationResult = CreateBargeNominationMutation['createBargeNomination']

type Values = {
  numberOfLoaded: number
  numberOfEmpties: number
}

const intialValues: Values = { numberOfLoaded: 0, numberOfEmpties: 0 }

export function GoalsDiff() {
  const [isScreenVisible, setIsScreenVisible] = useState(false)

  const [state, setState] = useState<Filter>(searchParamsToFilter(window.location.search))

  useEffect(() => {
    function locationChange() {
      setState(searchParamsToFilter(window.location.search))
    }

    window.addEventListener('pushState', locationChange)
    window.addEventListener('popstate', locationChange)

    return () => {
      window.removeEventListener('pushState', locationChange)
      window.removeEventListener('popstate', locationChange)
    }
  }, [])

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <PrimaryMenuRight>
            <LastUpdateIndicator />
            <ToggleFilterScreenButton handleClick={() => setIsScreenVisible(true)} />
          </PrimaryMenuRight>
        </PrimaryMenu>
      )}>
      {state.lane === undefined ? (
        <div className={styles.empty}>
          <h1>
            Please <button onClick={() => setIsScreenVisible(true)}>select a lane</button> and other settings in the
            filter screen
          </h1>
        </div>
      ) : (
        <Content {...state} lane={state.lane} showFilters={() => setIsScreenVisible(true)} />
      )}
      {isScreenVisible ? (
        <LaneFilters filters={state} setIsVisible={setIsScreenVisible} isEnabled readOnly={false} />
      ) : null}
    </Layout>
  )
}

function Content({
  lane,
  origin,
  destination,
  excludeBargeIds,
  excludeNominatedBarges,
  excludeTanks,
  excludeHoppers,
  excludePlacedToLoad,
  excludeTboInfo,
  maxDraft,
  time,
  showFilters,
}: Filter & { lane: LaneId; showFilters: () => void }) {
  const [nominations, setNominations] = useState<Record<GoalId, NominationResult>>()
  const { hubs, lanes } = useSettingsContext()
  const [{ fetching, data }] = useLaneBargesQuery({
    variables: {
      laneId: lane,
      originId: origin ?? null,
      destinationId: destination ?? null,
      excludeNominatedBarges,
      excludeBargeTypes: getExcludedBargeTypes(excludeTanks, excludeHoppers),
      excludeTripStatuses: excludePlacedToLoad ? excludePlacedToLoadStatuses : null,
      excludeTboInfoBarges: excludeTboInfo,
      maxDraft: maxDraft ?? null,
      time: time ? isoZonedDateTime(time) : null,
    },
  })

  const barges = useMemo(() => (data === undefined ? [] : fromBargesQuery(data.lanes[0].barges)), [data])

  const keys = useMemo(
    () => (excludeTboInfo ? columnKeys.filter(key => key !== 'tboInfo') : columnKeys),
    [excludeTboInfo]
  )

  const totalEmpties = barges.filter(isEmpty).length

  if (fetching || data === undefined) {
    return <LoadingSpinner isFullScreen />
  }

  return (
    <div className={styles.content}>
      <section className={styles.route}>
        <label onClick={showFilters}>
          lane
          <input type="text" readOnly value={lane ? lanes[lane] : ''} />
        </label>
        <label onClick={showFilters}>
          origin
          <input type="text" readOnly value={origin ? showRiverLocation(hubs[origin].riverLocation) : ''} />
        </label>
        <label onClick={showFilters}>
          destination
          <input type="text" readOnly value={destination ? showRiverLocation(hubs[destination].riverLocation) : ''} />
        </label>
      </section>
      <BargesTable lane={lane} barges={barges} columnKeys={keys} />
      {destination && origin ? (
        <NominationForm
          setNominations={setNominations}
          lane={lane}
          origin={origin}
          destination={destination}
          filters={{
            excludeBargeIds,
            excludeNominatedBarges,
            excludeTanks,
            excludeHoppers,
            excludePlacedToLoad,
            excludeTboInfo,
            maxDraft,
            time,
          }}
          totalBarges={barges.length}
          totalEmpties={totalEmpties}
        />
      ) : (
        <EmptyNominationForm />
      )}
      {nominations ? <Nominations hasTimeWindow={time !== undefined} nominationResults={nominations} /> : null}
    </div>
  )
}

export function BargesTableHeader({
  keys,
  sorting,
  handleSorting,
  setFiltering,
  isFiltered,
}: {
  keys: ColumnKey[]
  sorting?: SortingState<OverviewBarge>
  handleSorting: (column: Sortable<OverviewBarge>) => void
  isFiltered: boolean
  setFiltering: (search?: string) => void
}) {
  return (
    <>
      {keys.map(key => (
        <HeaderCell key={key}>
          {key === 'name' ? (
            <FilterColunm handleChange={setFiltering} isFiltered={isFiltered}>
              {columns[key].label}
            </FilterColunm>
          ) : (
            <SortableColumn
              isSorted={columns[key] === sorting?.sortable ? sorting?.dir : undefined}
              handleToggleSorting={() => handleSorting(columns[key])}>
              {columns[key].label}
            </SortableColumn>
          )}
        </HeaderCell>
      ))}
    </>
  )
}

function BargesTable({
  lane,
  barges,
  columnKeys: keys,
}: {
  lane: LaneId
  barges: OverviewBarge[]
  columnKeys: ColumnKey[]
}) {
  const [filtering, setFiltering] = useState<string>()
  const { sorting, setSorting } = useSorting<OverviewBarge>()

  const rows = useMemo(() => {
    if (sorting !== undefined) {
      const { sortable, dir } = sorting

      const sortedRows = sortable.sortBy(barges)

      return dir === 'desc' ? sortedRows.reverse() : sortedRows
    }

    if (filtering !== undefined) {
      const search = filtering.trim().toLowerCase()

      return barges.filter(({ barge: { name } }) => name !== null && name.toLowerCase().includes(search))
    }

    return groupOverviewBarges(barges, lane)
  }, [lane, barges, sorting, filtering])

  return (
    <section className={styles.tableSection}>
      <div className={styles.tableContainer}>
        <TB className={styles.table}>
          <TBHead>
            <TBR>
              <BargesTableHeader
                keys={keys}
                sorting={sorting}
                handleSorting={setSorting}
                isFiltered={filtering !== undefined}
                setFiltering={setFiltering}
              />
            </TBR>
          </TBHead>
          <TBBody>
            {rows.map((row, index) =>
              'kind' in row ? (
                <TBR key={row.key}>
                  <TableCell colSpan={keys.length} className={styles.groupHeader}>
                    <span className={styles.element}>{row.key}</span>
                  </TableCell>
                </TBR>
              ) : (
                <TBR key={`${row.barge.id}-${index}`} isOdd={sorting !== undefined && index % 2 > 0}>
                  {keys.map(key => (
                    <TableCell key={key}>{columns[key].format(row)}</TableCell>
                  ))}
                </TBR>
              )
            )}
          </TBBody>
        </TB>
      </div>
    </section>
  )
}
const EmptyNominationForm = () => (
  <section className={styles.formSection}>
    <div className={styles.entry}>
      <label className={styles.label}>
        Number of loaded barges
        <input disabled className={styles.input} type="number" value={0} />
      </label>
    </div>
    <div className={styles.entry}>
      <label className={styles.label}>
        Number of empty barges
        <input disabled className={styles.input} type="number" value={0} />
      </label>
    </div>
    <div>
      <button type="submit" onClick={() => errorToast('Please select origin/destination from filter screen')}>
        Nominate Barges
      </button>
    </div>
  </section>
)

function NominationForm({
  setNominations,
  totalEmpties,
  totalBarges,
  lane,
  origin,
  destination,
  filters,
}: {
  setNominations: (res: Record<GoalId, NominationResult>) => void
  lane: LaneId
  origin: HubLikeId
  destination: HubLikeId
  filters: Filter
  totalEmpties: number
  totalBarges: number
}) {
  const { goals } = useSettingsContext()
  const [, createBargeNomination] = useCreateBargeNominationMutation()

  const bargeFilters = useMemo(() => {
    const {
      excludeBargeIds,
      excludeNominatedBarges,
      excludeTanks,
      excludePlacedToLoad,
      excludeHoppers,
      excludeTboInfo,
      maxDraft,
      time,
    } = filters

    return {
      excludeBargeIds: excludeBargeIds ?? [],
      excludeNominatedBarges: excludeNominatedBarges ?? false,
      excludeBargeTypes: getExcludedBargeTypes(excludeTanks, excludeHoppers),
      excludeTripStatuses: excludePlacedToLoad ? excludePlacedToLoadStatuses : [],
      excludeTboInfoBarges: excludeTboInfo,
      maximumDraft: maxDraft ?? null,
      expectedDepartureTime: time ? isoZonedDateTime(time) : null,
    }
  }, [filters])

  const { expectedDepartureTime } = bargeFilters
  const goalIds = useMemo(() => {
    return Object.keys(goals)
      .filter(isGoalId)
      .filter(
        g =>
          (expectedDepartureTime || g !== GoalId.LinehaulTurnTime) &&
          (shortDropLanes.includes(lane) || g !== GoalId.ShortDropTow)
      )
  }, [goals, expectedDepartureTime, lane])

  const handleSubmit = useCallback(
    ({ numberOfLoaded, numberOfEmpties }: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const {
        excludeBargeIds,
        excludeNominatedBarges,
        excludeBargeTypes,
        excludeTripStatuses,
        excludeTboInfoBarges,
        maximumDraft,
      } = bargeFilters

      const variables = {
        lane,
        origin,
        destination,
        excludeBargeIds,
        excludeNominatedBarges,
        excludeBargeTypes,
        excludeTripStatuses,
        excludeTboInfoBarges,
        maximumDraft,
        expectedDepartureTime,
        prioritizeHotBarges: false,
      }

      Promise.all(
        goalIds.map(goal => {
          const towConfiguration = {
            goal,
            numberOfBarges: numberOfLoaded + numberOfEmpties,
            numberOfEmptyBarges: numberOfEmpties,
            numberOfStrings: null,
            preselectedBarges: [],
            boat: null,
            hasTurnboat: false,
          }
          return createBargeNomination({
            bargeNomination: {
              ...variables,
              towConfiguration,
            },
          })
        })
      ).then(res => {
        setSubmitting(false)

        const nominationResult = res.reduce(
          (acc: Record<GoalId, NominationResult>, { data }, index): Record<GoalId, NominationResult> => {
            if (data) {
              const goal = goalIds[index]

              acc[goal] = data.createBargeNomination
            }

            return acc
          },
          {} as Record<GoalId, NominationResult>
        )

        setNominations(nominationResult)
      })
    },
    [setNominations, createBargeNomination, lane, origin, destination, goalIds, bargeFilters, expectedDepartureTime]
  )

  return (
    <Formik initialValues={intialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className={styles.formSection}>
          <div className={styles.entry}>
            <label className={styles.label}>
              Number of loaded barges
              <input
                className={styles.input}
                type="number"
                min={0}
                onChange={e => setFieldValue('numberOfLoaded', e.target.valueAsNumber)}
                value={values.numberOfLoaded}
              />
              <span className={styles.minMax}>(0 - {totalBarges - totalEmpties})</span>
            </label>
          </div>
          <div className={styles.entry}>
            <label className={styles.label}>
              Number of empty barges
              <input
                className={styles.input}
                type="number"
                min={0}
                onChange={e => setFieldValue('numberOfEmpties', e.target.valueAsNumber)}
                value={values.numberOfEmpties}
              />
              <span className={styles.minMax}>(0 - {totalEmpties})</span>
            </label>
          </div>
          <div>
            <button disabled={isSubmitting} type="submit">
              {isSubmitting && <Spinner className={styles.spinner} />}
              Nominate Barges
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

function Nominations({
  nominationResults,
  hasTimeWindow,
}: {
  nominationResults: Record<GoalId, NominationResult>
  hasTimeWindow: boolean
}) {
  const { hubs } = useSettingsContext()
  const goalKeys = useMemo(
    () => [GoalId.BuildToDestination, GoalId.FleetEfficiency, GoalId.LinehaulTurnTime, GoalId.ShortDropTow],
    []
  )
  const goalBargeIds = useMemo(() => {
    const init: Record<GoalId, string[]> = {
      [GoalId.BuildToDestination]: [],
      [GoalId.FleetEfficiency]: [],
      [GoalId.LinehaulTurnTime]: [],
      [GoalId.ShortDropTow]: [],
    }

    return Object.entries(nominationResults).reduce((acc: Record<GoalId, string[]>, [key, res]) => {
      if (res.__typename === 'NominationCreateSuccess' && isGoalId(key)) {
        acc[key] = res.nomination.tows[0].barges.map(b => b.id)
      }
      return acc
    }, init)
  }, [nominationResults])

  const rows = useMemo(
    () =>
      goalKeys
        .map(key => {
          const res = nominationResults[key]
          return res?.__typename === 'NominationCreateSuccess'
            ? res.nomination.tows[0].barges.map(barge => ({
                barge,
                id: barge.id,
                goals: Object.entries(goalBargeIds)
                  .map(([goal, bargeIds]) => (bargeIds.includes(barge.id) ? goal : null))
                  .filter(isGoalId),
              }))
            : []
        })
        .flat()
        .sort((a, b) => {
          if (a.goals.length === b.goals.length) {
            return 0
          }

          return a.goals.length > b.goals.length ? 1 : -1
        }),
    [nominationResults, goalKeys, goalBargeIds]
  )

  return (
    <section className={styles.results}>
      <div className={styles.combined}>
        <CombinedBargesTable
          rows={rows}
          hasTimeWindow={hasTimeWindow}
          goalBargeCount={goalKeys.reduce((acc: Record<string, number>, key) => {
            acc[key] = goalBargeIds[key].length
            return acc
          }, {})}
        />
      </div>
      <div className={styles.nominations}>
        {goalKeys.map(key => {
          const res = nominationResults[key]

          return res?.__typename === 'NominationCreateSuccess' ? (
            <div key={key} className={styles.nominationList}>
              <div className={styles.nominationListTitle}>
                {hubs[res.nomination.userRequest.bargeFilters.towOrigin].label} <span className={styles.to}>to</span>{' '}
                {hubs[res.nomination.userRequest.bargeFilters.towDestination].label}
              </div>
              <NominationListItemHeader {...res.nomination.tows[0]} goal={key} />
              <GoalNominatedBargesTable
                className={styles.nominationTable}
                key={key}
                goalCount={
                  res.nomination.tows.length > 0
                    ? res.nomination.tows[0].barges.reduce((acc: Record<string, number>, b) => {
                        acc[b.id] = Object.entries(goalBargeIds)
                          .map(([goal, bargeIds]) => (bargeIds.includes(b.id) ? goal : null))
                          .filter(isGoalId).length
                        return acc
                      }, {})
                    : {}
                }
                barges={res.nomination.tows.length > 0 ? res.nomination.tows[0].barges : []}
              />
            </div>
          ) : null
        })}
      </div>
      <footer className={styles.legend}>
        <div className={styles.entry}>2 common goals</div>
        <div className={styles.entry}>3 common goals</div>
        <div className={styles.entry}>4 common goals</div>
      </footer>
    </section>
  )
}
