import { TripOrigin } from '@mui/icons-material'

import { type HubLike, HubLikeId } from '../../../generated/graphql'
import ComboboxSelector from '../ComboboxSelector/ComboboxSelector'

function isHubLikeId(key: any, hubs: Record<HubLikeId, { label: string }>): key is HubLikeId {
  return key in hubs
}

export function OriginSelectorCombobox({
  origin,
  origins,
  handleChange,
}: {
  origin?: string
  origins: Record<HubLikeId, HubLike>
  handleChange: (item: string) => void
}) {
  return (
    <ComboboxSelector
      value={origin}
      handleChange={handleChange}
      items={origins}
      displayValue={(item: string | undefined) => {
        return item && isHubLikeId(item, origins) ? origins[item].label : ''
      }}
      placeholder="Select origin"
      IconComponent={TripOrigin}
    />
  )
}
