import { GraphqlBargeType as BargeType } from '../generated/graphql'

const excludeBargeTankTypes = [BargeType.TankCaustic, BargeType.TankChemical, BargeType.TankFuel]
const excludeOpenHopperTypes = [BargeType.HoppOpen]

export const getExcludedBargeTypes = (excludeTanks: boolean, excludeHoppers: boolean) => [
  ...(excludeTanks ? excludeBargeTankTypes : []),
  ...(excludeHoppers ? excludeOpenHopperTypes : []),
]

export const isExcludingTanks = (bargeTypes: BargeType[]) => excludeBargeTankTypes.every(t => bargeTypes.includes(t))
export const isExcludingHoppers = (bargeTypes: BargeType[]) => excludeOpenHopperTypes.every(t => bargeTypes.includes(t))
