import React, { type FC } from 'react'

import { Journey } from '../../../components/Nomination/Journey'

import styles from './styles.module.scss'

import type { JourneyData } from './viewModel'

type JourneyBlockProps = {
  readonly journey: JourneyData
}

const JourneyBlock: FC<JourneyBlockProps> = ({ journey }) => {
  const { boat, stops, stopSelectionHandler } = journey

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <Journey stops={stops} handleSelectStop={stopSelectionHandler} boat={boat} />
      </div>
    </div>
  )
}

export default JourneyBlock
