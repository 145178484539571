import { SportsScore } from '@mui/icons-material'

import { type HubLike, HubLikeId } from '../../../generated/graphql'
import ComboboxSelector from '../ComboboxSelector/ComboboxSelector'

function isHubLikeId(key: any, hubs: Record<HubLikeId, { label: string }>): key is HubLikeId {
  return key in hubs
}

export function DestinationSelectorCombobox({
  destination,
  destinations,
  handleChange,
}: {
  destination?: string
  destinations: Record<HubLikeId, HubLike>
  handleChange: (item: string) => void
}) {
  return (
    <ComboboxSelector
      value={destination}
      handleChange={handleChange}
      items={destinations}
      displayValue={(item: string | undefined) => {
        return item && isHubLikeId(item, destinations) ? destinations[item].label : ''
      }}
      placeholder="Select destination"
      IconComponent={SportsScore}
    />
  )
}
