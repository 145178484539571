import React, { FC } from 'react'

import { BargeListing } from '../../../components/BargeListing/BargeListing'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import styles from './styles.module.scss'

import type { BargePoolViewModel } from './types'

const BargePoolBlock: FC<BargePoolViewModel> = ({ lane, barges, isFetching }) => {
  if (isFetching) {
    return (
      <div className={styles.centeredSpinner}>
        <LoadingSpinner isFullScreen={false} />
      </div>
    )
  }

  return (
    <div className={styles.bargePoolRoot}>
      <div className={styles.bargePoolTitle}>Barge pool</div>
      <div className={styles.bargePoolWrapper}>
        <BargeListing
          lane={lane}
          barges={barges}
          isSelectable={() => false}
          pinnedBarges={[]}
          setPinnedBarges={() => {}}
          excludedBarges={[]}
          setExcludedBarges={() => {}}
        />
      </div>
    </div>
  )
}

export default BargePoolBlock
