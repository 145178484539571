import React, { type FC } from 'react'

import { Form, Formik } from 'formik'

import { DestinationSelectorCombobox } from '../../../components/Header/DestinationSelector/DestinationSelector'
import { LaneSelectorCombobox } from '../../../components/Header/LaneSelector/LaneSelector'
import { OriginSelectorCombobox } from '../../../components/Header/OriginSelector/OriginSelector'

import FormOrSummary from './FormOrSummary'
import styles from './styles.module.scss'
import { LaneSelectionFormValues, LaneSelectionSummaryValues, type StageData } from './types'
import { laneSelectionInitialValues, laneSelectionValidationSchema } from './viewModel'

import type { HubLikeId, LaneId } from '../../../generated/graphql'

const LaneSelectionForm: FC<{
  data: LaneSelectionFormValues
  onChange: (d: LaneSelectionFormValues) => void
  initParameters: any
}> = ({ data, onChange, initParameters }) => {
  const { lanes, origins, destinations } = initParameters

  return (
    <Formik
      initialValues={laneSelectionInitialValues}
      validationSchema={laneSelectionValidationSchema}
      onSubmit={() => {}}>
      {({ setFieldValue }) => (
        <Form className={styles.nominationForm}>
          <div className={styles.formRow}>
            <label className={styles.label}>Lane</label>
            <div className={styles.inputContainer}>
              <LaneSelectorCombobox
                lane={data.laneId}
                lanes={lanes}
                handleChange={item =>
                  setFieldValue('laneId', item as LaneId).then(() =>
                    onChange({ ...data, laneId: item as LaneId, origin: undefined, destination: undefined })
                  )
                }
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Origin</label>
            <div className={styles.inputContainer}>
              <OriginSelectorCombobox
                origin={data.origin}
                origins={origins}
                handleChange={item =>
                  setFieldValue('origin', item as HubLikeId).then(() =>
                    onChange({ ...data, origin: item as HubLikeId, destination: undefined })
                  )
                }
              />
            </div>
          </div>
          <div className={styles.formRow}>
            <label className={styles.label}>Destination</label>
            <div className={styles.inputContainer}>
              <DestinationSelectorCombobox
                destination={data.destination}
                destinations={destinations}
                handleChange={item =>
                  setFieldValue('destination', item as HubLikeId).then(() =>
                    onChange({ ...data, destination: item as HubLikeId })
                  )
                }
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const LaneSelectionSummary: FC<LaneSelectionSummaryValues> = ({ description }) => {
  return <div>{description}</div>
}

const LaneSelectionBlock: FC<
  StageData<
    { data: LaneSelectionFormValues; onChange: (d: LaneSelectionFormValues) => void },
    LaneSelectionSummaryValues,
    any
  >
> = ({ isSelected, actions, form, summary, initParameters }) => {
  const { data, onChange } = form

  return (
    <FormOrSummary
      title="Lane and route"
      isSelected={isSelected}
      actions={actions}
      form={<LaneSelectionForm data={data} onChange={onChange} initParameters={initParameters} />}
      summary={<LaneSelectionSummary {...summary} />}
    />
  )
}

export default LaneSelectionBlock
