import React, { type FC } from 'react'

import classnames from 'classnames'

import { NominatedBargesTable, Theme } from '../../../components/Table/NominatedBargesTable'

import styles from './styles.module.scss'

import type { NominatedBarge } from '../../../models/models'

type BargeListBlockProps = {
  readonly nominatedBarges: NominatedBarge[]
}

const theme: Theme = {
  columns: {
    isAvailable: styles.isAvailableCell,
    tboInfo: styles.tboCell,
    inOtherNomination: styles.inOtherNominationCell,
    name: styles.nameCell,
    isAtRisk: styles.isAtRiskCell,
    cargo: styles.cell,
    destination: styles.cell,
    hullType: styles.hullTypeCell,
    tripStatus: styles.cell,
    pickUp: styles.locationCell,
    dropOff: styles.locationCell,
    currentLocation: styles.locationCell,
    pickupType: styles.cell,
    type: styles.cell,
  },
}

const BargeListBlock: FC<BargeListBlockProps> = ({ nominatedBarges }) => {
  return (
    <div className={classnames([styles.container, styles.nominatedBarges])}>
      <NominatedBargesTable hasReviews barges={nominatedBarges} theme={theme} />
    </div>
  )
}

export default BargeListBlock
