import React, { type FC } from 'react'

import FormOrSummary from './FormOrSummary'

import type { StageData } from './types'

const DepartureTimeSelectionForm: FC<{}> = () => {
  return <div>Departure selection form</div>
}

const DepartureTimeSelectionSummary: FC<{}> = () => {
  return <div>Not set</div>
}

const DepartureTimeSelectionBlock: FC<StageData<any, any, any>> = ({ isSelected, actions }) => {
  return (
    <FormOrSummary
      title="Departure time"
      isSelected={isSelected}
      actions={actions}
      form={<DepartureTimeSelectionForm />}
      summary={<DepartureTimeSelectionSummary />}
    />
  )
}

export default DepartureTimeSelectionBlock
