import React, { type FC } from 'react'

import BoatSelectorCombobox from '../../../components/Header/VesselSelector/VesselSelector'
import { Toggle } from '../../../ui/Toggle/Toggle'

import FormOrSummary from './FormOrSummary'
import styles from './styles.module.scss'

import type { StageData, VesselSelectionFormValues, VesselSelectionSummaryValues } from './types'

const VesselSelectionForm: FC<{
  data: VesselSelectionFormValues
  onChange: (d: VesselSelectionFormValues) => void
  initParameters: any
}> = ({ data, onChange, initParameters }) => {
  const { boats } = initParameters

  return (
    <div className={styles.formRow}>
      <label className={styles.label}>Select a Boat</label>
      <div className={styles.inputContainer}>
        <BoatSelectorCombobox
          boat={data.boatId}
          boats={boats}
          handleChange={value => onChange({ ...data, boatId: value })}
        />
      </div>
      <div className={styles.flexTurnboat}>
        <label className={styles.label}>Turnboat</label>
        <Toggle enabled={data.hasTurnboat} onChange={value => onChange({ ...data, hasTurnboat: value })} />
      </div>
    </div>
  )
}

const VesselSelectionSummary: FC<VesselSelectionSummaryValues> = ({ description }) => {
  return <div>{description}</div>
}

const VesselSelectionBlock: FC<StageData<any, VesselSelectionSummaryValues, any>> = ({
  isSelected,
  actions,
  form,
  summary,
  initParameters,
}) => {
  const { data, onChange } = form

  return (
    <FormOrSummary
      title="Vessels"
      isSelected={isSelected}
      actions={actions}
      form={<VesselSelectionForm data={data} onChange={onChange} initParameters={initParameters} />}
      summary={<VesselSelectionSummary {...summary} />}
    />
  )
}

export default VesselSelectionBlock
