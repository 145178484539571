import React, { type FC } from 'react'

import { PrimaryMenu, PrimaryMenuRight } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { Layout } from '../../../ui/Layout/Layout'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import styles from './styles.module.scss'
import useNominationsViewModel, { type NominationCard } from './viewModel'

const NominationTile: FC<NominationCard> = nomination => {
  return (
    <div className={styles.nomination} onClick={() => nomination.open()} key={nomination.id}>
      <div className={styles.title}>{nomination.title}</div>
      <div className={styles.details}>
        <div className={styles.general}>
          <span className={styles.label}>Creation date:</span>
          <span className={styles.value}>{nomination.recordTime}</span>
        </div>
        <div className={styles.tbo}>
          <span className={styles.label}>TBO Status:</span>
          <span className={styles.value}>...</span>
        </div>
      </div>
    </div>
  )
}

const NominationListPage = () => {
  const { fetching, newNomination, nominations } = useNominationsViewModel()
  const nominationCards = nominations.map(NominationTile)

  if (fetching) {
    return <LoadingSpinner isFullScreen />
  }

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen} />
      )}>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.header}>
            <div className={styles.newNominationHeader}>
              <h3>Nominations</h3>
              <PrimaryMenuRight>
                <button onClick={newNomination} className={styles.newNomination}>
                  New nomination
                </button>
              </PrimaryMenuRight>
            </div>
          </div>
          <div className={styles.nominations}>{nominationCards}</div>
        </div>
      </div>
    </Layout>
  )
}

export default NominationListPage
