import { useMemo } from 'react'

import { Help } from '@mui/icons-material'
import classnames from 'classnames'

import { ELLIPSIS } from '../../../constants/constants'
import { isEmptyLoadStatus } from '../../../Domain/Barge'
import { NominatedBarge, showBoat, showTransitTime, showTruncatedMilesMetric } from '../../../Domain/Nomination'
import { showRiverLocation } from '../../../Domain/River'
import {
  GoalId,
  HubLikeId,
  HullType,
  LaneId,
  LoadStatus,
  NominatedTbnBarge,
  TboSubmissionStatusId,
} from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { Popover } from '../../../ui/Popover/Popover'
import { toString } from '../../../utils/date'

import styles from './NominationSummary.module.scss'


function getBargeTotals(barges: NominatedBarge[]) {
  const { empties, loaded } = barges.reduce(
    (acc: { empties: NominatedBarge[]; loaded: NominatedBarge[] }, b) =>
      b.expectedLoadStatus && isEmptyLoadStatus(b.expectedLoadStatus)
        ? { ...acc, empties: [...acc.empties, b], loaded: acc.loaded }
        : { ...acc, empties: acc.empties, loaded: [...acc.loaded, b] },
    { empties: [], loaded: [] }
  )

  return {
    totalNumberOfBarges: barges.length,
    totalNumberOfHotBarges: barges.filter(_ => _.isHot).length,
    totalNumberOfEmptyRakes: empties.filter(_ => _.hullType === HullType.Rake).length,
    totalNumberOfEmptyBoxes: empties.filter(_ => _.hullType === HullType.Box).length,
    totalNumberOfLoadedRakes: loaded.filter(_ => _.hullType === HullType.Rake).length,
    totalNumberOfLoadedBoxes: loaded.filter(_ => _.hullType === HullType.Box).length,
    distinctDestinations: barges.reduce((acc: string[], b) => {
      const loc = b.destination ? showRiverLocation(b.destination) : null

      return loc && !acc.includes(loc) ? [...acc, loc] : acc
    }, []).length,
  }
}

const dateTimeFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

const tboStatusClasses = {
  [TboSubmissionStatusId.Pending]: null,
  [TboSubmissionStatusId.Submitted]: styles.tboSubmissionSuccess,
  [TboSubmissionStatusId.NotSubmitted]: null,
  [TboSubmissionStatusId.Failed]: styles.tboSubmissionFailure,
}

type Props = {
  lane: LaneId
  origin: HubLikeId
  destination: HubLikeId
  createdAt?: Date
  goal: GoalId
  transitTimeMinutes: number
  barges: NominatedBarge[]
  score: number | null
  numberOfStops: number
  remainingBargeMiles: number
  hasTurnboat: boolean
  boat: string
  tboSubmissionStatus: TboSubmissionStatusId
  tbnBarges: NominatedTbnBarge[]
  timeWindow?: Date
  bargesAtStopMax: number
  numberOfStrings: number | null
}
export function NominationSummary({
  lane,
  origin,
  destination,
  createdAt,
  goal,
  transitTimeMinutes,
  barges,
  score,
  tbnBarges,
  numberOfStops,
  remainingBargeMiles,
  hasTurnboat,
  boat,
  tboSubmissionStatus,
  timeWindow,
  bargesAtStopMax,
  numberOfStrings,
}: Props) {
  const { hubs, goals, lanes, tboSubmissionStatuses } = useSettingsContext()
  const padLength = barges.length.toString().length
  const {
    totalNumberOfBarges,
    totalNumberOfHotBarges,
    totalNumberOfEmptyBoxes,
    totalNumberOfEmptyRakes,
    totalNumberOfLoadedRakes,
    totalNumberOfLoadedBoxes,
    distinctDestinations,
  } = useMemo(() => getBargeTotals(barges), [barges])
  return (
    <>
      <div className={styles.nominationSummary}>
        <h3 className={styles.title}>Nomination Summary</h3>
        <div className={styles.info}>
          <span className={styles.label}>
            <span>Lane</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{lanes[lane]}</span>
          <span className={styles.label}>
            <span>Origin</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{showRiverLocation(hubs[origin].riverLocation)}</span>
          <span className={styles.label}>
            <span>Destination</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{showRiverLocation(hubs[destination].riverLocation)}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Nomination time</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{createdAt ? toString(createdAt, dateTimeFormat) : ELLIPSIS}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Expected departure time</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{timeWindow ? toString(timeWindow, dateTimeFormat) : ELLIPSIS}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Ops. goal</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{goals[goal].label}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Tot. transit time</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{transitTimeMinutes ? showTransitTime(transitTimeMinutes) : ELLIPSIS}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Remaining barge miles</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{showTruncatedMilesMetric(remainingBargeMiles)}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Turnboat</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{hasTurnboat.toString()}</span>
          <hr className={styles.spacer} />
          <span className={styles.label}>
            <span>Vessel</span>
            <span>:</span>
          </span>
          <span className={styles.value}>{showBoat(boat)}</span>
          <h2 className={styles.bigNumber}>
            <span className={styles.number}>{totalNumberOfBarges.toString().padStart(padLength, '0')}</span>
            <span>Barges in total</span>
            <span className={styles.subText}>{`(Max ${bargesAtStopMax} in-tow at any time)`}</span>
          </h2>
          <h2 className={styles.bigNumber}>
            <span className={styles.number}>{totalNumberOfHotBarges.toString().padStart(padLength, '0')}</span>
            <span>Hot barges in total</span>
          </h2>
        </div>
      </div>
      <div className={styles.nominationSummary}>
        <h3 className={styles.title}>Version Summary</h3>
        <div className={styles.info}>
          <span className={styles.label}>
            <span>TBO status</span>
            <span>:</span>
          </span>
          <span className={classnames(styles.value, tboStatusClasses[tboSubmissionStatus])}>
            {tboSubmissionStatuses[tboSubmissionStatus]}
          </span>
        </div>
      </div>
      <TowMetric score={score} goal={goals[goal].label} />
      <TBNBarges tbnBarges={tbnBarges} />
      <div className={styles.stats}>
        <div className={styles.statsEntry}>
          <span className={styles.statsLabel}>Total stops</span>
          <span className={styles.statsValue}>{numberOfStops.toString().padStart(2, '0')}</span>
        </div>
        <div className={styles.statsEntry}>
          <span className={styles.statsLabel}>Total dest.</span>
          <span className={styles.statsValue}>{distinctDestinations.toString().padStart(2, '0')}</span>
        </div>
        <div className={styles.statsEntry}>
          <span className={styles.statsLabel}>Num. of strings</span>
          <span className={styles.statsValue}>
            {numberOfStrings ? numberOfStrings.toString().padStart(2, '0') : ELLIPSIS}
          </span>
        </div>
        <div className={styles.statsEntry}>
          <span className={styles.statsLabel}>Total empties</span>
          <span className={styles.statsValue}>
            {(totalNumberOfEmptyBoxes + totalNumberOfEmptyRakes).toString().padStart(padLength, '0')}
          </span>
          <span className={styles.statsLabel}>Rakes</span>
          <span className={styles.statsValue}>{totalNumberOfEmptyRakes.toString().padStart(padLength, '0')}</span>
          <span className={styles.statsLabel}>Boxes</span>
          <span className={styles.statsValue}>{totalNumberOfEmptyBoxes.toString().padStart(padLength, '0')}</span>
        </div>
        <div className={styles.statsEntry}>
          <span className={styles.statsLabel}>Total loaded</span>
          <span className={styles.statsValue}>
            {(totalNumberOfLoadedBoxes + totalNumberOfLoadedRakes).toString().padStart(padLength, '0')}
          </span>
          <span className={styles.statsLabel}>Rakes</span>
          <span className={styles.statsValue}>{totalNumberOfLoadedRakes.toString().padStart(padLength, '0')}</span>
          <span className={styles.statsLabel}>Boxes</span>
          <span className={styles.statsValue}>{totalNumberOfLoadedBoxes.toString().padStart(padLength, '0')}</span>
        </div>
      </div>
    </>
  )
}

function TowMetric({ score, goal }: { score: number | null; goal: string }) {
  const hue = Math.max(Math.min((score ?? 0) - 0.5, 0.5), 0) * 240

  const [int, decimal] = score ? (score * 100).toFixed(2).split('.') : [null, null]

  return (
    <div
      className={classnames(styles.tile, styles.scoreContainer)}
      style={{ backgroundColor: `hsl(${hue}, 50%, 50%)` }}>
      <div className={styles.popoverButton}>
        <Popover button={<Help />} placement="top-end" theme={{ popover: styles.popover }}>
          <h3>Total barge-miles</h3>
          <p>
            Total barge-miles (the number of miles each barge travels) in the itinerary of a nomination list divided by
            the maximum barge-miles that can be pushed over the same tow route
          </p>
        </Popover>
      </div>
      <h5 className={styles.header}>Tow score by user</h5>
      <div className={styles.content}>
        <h2 className={styles.score}>
          {int !== null && decimal !== null ? (
            <>
              <span className={styles.number}>{int}</span>
              <span className={styles.decimal}>.{decimal}%</span>
            </>
          ) : (
            <span className={styles.number}>{ELLIPSIS}</span>
          )}
        </h2>
        <span>{goal}</span>
      </div>
    </div>
  )
}

const TBNBarges = ({ tbnBarges }: { tbnBarges: NominatedTbnBarge[] }) => {
  const groupedBarges = useMemo(() => {
    const result = tbnBarges.reduce<Record<string, { loaded: number; empty: number; pickup: string; dropOff: string }>>(
      (acc, barge) => {
        const pickupLabel = showRiverLocation(barge.pickupFacility)
        const dropOffLabel = showRiverLocation(barge.dropOffFacility)
        const key = `${pickupLabel}-${dropOffLabel}`

        if (!acc[key]) {
          acc[key] = { loaded: 0, empty: 0, pickup: pickupLabel, dropOff: dropOffLabel }
        }

        if (barge.expectedLoadStatus === LoadStatus.Loaded) {
          acc[key].loaded += 1
        } else if (barge.expectedLoadStatus === LoadStatus.Empty) {
          acc[key].empty += 1
        }

        return acc
      },
      {}
    )
    return result
  }, [tbnBarges])

  return (
    <div className={styles.tbnBargesSummary}>
      <h5 className={styles.tbnBargesTitle}>TBN Barges</h5>
      {Object.entries(groupedBarges).map(([key, { loaded, empty, pickup, dropOff }]) => (
        <div key={key} className={styles.tbnBargesInfo}>
          <div className={styles.tbnBargesLabel}>
            <span>
              From {pickup} to {dropOff}
            </span>
          </div>
          <div className={styles.tbnBargesLoadCounts}>
            <span className={styles.tbnBargesLabel}>LOADED</span>
            <span className={styles.tbnBargesValue}>{loaded.toString().padStart(2, '0')}</span>
            <span className={styles.tbnBargesLabel}>EMPTY</span>
            <span className={styles.tbnBargesValue}>{empty.toString().padStart(2, '0')}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
