import { useMemo, useState } from 'react'

import * as R from 'ramda'
import { object as YupObject, string as YupString } from 'yup'

import { fromBargesQuery } from '../../../Domain/Barge'
import { lanesOriginDestFilteringRecord } from '../../../Domain/Hub'
import { showRiverLocation } from '../../../Domain/River'
import { type HubLike, type HubLikeId, type LaneId, useLaneBargesQuery } from '../../../generated/graphql'
import { formatBoat } from '../../../lib/formatters'
import { useSettingsContext } from '../../../providers/SettingsProvider'

import { createFormData } from './form'
import { createStageNavigation } from './navigation'
import {
  isDepartureTimeSelectionStage,
  isLaneSelectionStage,
  isPoolFiltersSelectionStage,
  isTowParametersSelectionStage,
  isVesselSelectionStage,
  LaneSelectionFormValues,
  NominationFormViewModel,
  NominationStage,
  StageData,
} from './types'

import type { NominatableBoat } from '../../../Domain/Nomination'

export const laneSelectionInitialValues: LaneSelectionFormValues = {
  laneId: undefined,
  origin: undefined,
  destination: undefined,
}

export const laneSelectionValidationSchema = YupObject().shape(
  {
    laneId: YupString().required('Required'),
    origin: YupString().required('Required'),
    destination: YupString().required('Required'),
  },
  []
)

const buildLaneDescription = (
  laneSelection: LaneSelectionFormValues,
  lanes: Record<LaneId, string>,
  hubs: Record<HubLikeId, HubLike>
): string => {
  const { laneId, origin, destination } = laneSelection
  if (!laneId || !origin || !destination) {
    return 'Set a location'
  }
  const lane = lanes[laneId]
  const originLocation = showRiverLocation(hubs[origin].riverLocation)
  const destinationLocation = showRiverLocation(hubs[destination].riverLocation)

  return `${lane}: ${originLocation} to ${destinationLocation}`
}

const buildVesselDescription = (boatId: string | undefined, hasTurnboat: boolean, boats: NominatableBoat[]): string => {
  if (!boatId) return 'Not set'

  const boatName = formatBoat(boatId, boats)
  const turnboatPart = hasTurnboat ? 'with turnboat' : 'without turnboat'
  return `${boatName} ${turnboatPart}`
}

type VesselSelectionFormValues = {
  boatId: string | undefined
  hasTurnboat: boolean
}

type FormState = {
  currentStage: NominationStage
  laneSelectionForm: LaneSelectionFormValues
  vesselSelectionForm: VesselSelectionFormValues
  departureTimeSelectionForm: any
  poolFiltersSelectionForm: any
  towParametersSelectionForm: any
}

const initialNavigationState = {
  stage: NominationStage.LaneSelection,
}

const initialFormState: FormState = {
  currentStage: NominationStage.LaneSelection,
  laneSelectionForm: {
    laneId: undefined,
    origin: undefined,
    destination: undefined,
  },
  vesselSelectionForm: {
    boatId: undefined,
    hasTurnboat: false,
  },
  departureTimeSelectionForm: {},
  poolFiltersSelectionForm: {},
  towParametersSelectionForm: {},
}

const toBargePoolRequestParameters = (laneParameters: LaneSelectionFormValues) => {
  return {
    laneId: laneParameters.laneId ? laneParameters.laneId : null,
    originId: laneParameters.origin ? laneParameters.origin : null,
    destinationId: laneParameters.destination ? laneParameters.destination : null,
    excludeNominatedBarges: false,
    excludeBargeTypes: [],
    excludeTripStatuses: null,
    excludeTboInfoBarges: false,
    maxDraft: null,
    time: null,
  }
}

const filterOrigins = (lane: LaneId | undefined, origins: Record<HubLikeId, HubLike>): Record<HubLikeId, HubLike> => {
  const availableOrigins = lane !== undefined ? Object.keys(lanesOriginDestFilteringRecord[lane]) : []
  const originFilter = (hub: HubLike): boolean => {
    return availableOrigins.includes(hub.id)
  }
  return R.filter((hub: HubLike) => originFilter(hub), origins)
}

const filterDestinations = (
  lane: LaneId | undefined,
  origin: HubLikeId | undefined,
  destinations: Record<HubLikeId, HubLike>
): Record<HubLikeId, HubLike> => {
  const availableDestinations =
    lane !== undefined && origin !== undefined ? lanesOriginDestFilteringRecord[lane][origin] || [] : []
  const destinationFilter = (hub: HubLike): boolean => {
    return availableDestinations.includes(hub.id)
  }
  return R.filter((hub: HubLike) => destinationFilter(hub), destinations)
}

const useNominationFormViewModel = (): NominationFormViewModel => {
  const [stage, setStage] = useState(initialNavigationState.stage)

  // const [laneSelectionForm, changeLaneSelectionForm] = useState(laneSelectionInitialValues)
  // const [vesselSelectionForm, changeVesselSelectionForm] = useState({})
  // const [departureTimeSelectionForm, changeDepartureTimeSelectionForm] = useState({})
  // const [towParametersSelectionForm, changeTowParametersSelectionForm] = useState({})

  const [formState, setFormState] = useState<FormState>(initialFormState)

  const { lanes, hubs, boats } = useSettingsContext()

  const bargePoolParameters = toBargePoolRequestParameters(formState.laneSelectionForm)
  const [{ data: bargesInPool, fetching: isFetchingBargePool }] = useLaneBargesQuery({
    variables: bargePoolParameters,
  })
  const bargePool = useMemo(
    () => ({
      lane: formState.laneSelectionForm.laneId,
      barges: bargesInPool ? fromBargesQuery(bargesInPool.lanes[0].barges) : [],
      isFetching: isFetchingBargePool,
    }),
    [formState, bargesInPool, isFetchingBargePool]
  )

  const updateLaneSelectionForm = (values: LaneSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      laneSelectionForm: values,
    }))
  }

  const updateVesselSelectionForm = (values: VesselSelectionFormValues) => {
    setFormState(prev => ({
      ...prev,
      vesselSelectionForm: values,
    }))
  }

  const currentStage = {
    stage,
  }

  const laneSelectionInitParams = useMemo(() => {
    const { laneId, origin } = formState.laneSelectionForm
    const origins = filterOrigins(laneId, hubs)
    const destinations = filterDestinations(laneId, origin, hubs)
    return {
      lanes,
      origins,
      destinations,
    }
  }, [formState, lanes, hubs])

  const vesselSelectionInitParams = useMemo(
    () => ({
      boats,
    }),
    [boats]
  )

  const stages: Record<NominationStage, StageData<any, any, any>> = {
    [NominationStage.LaneSelection]: {
      isSelected: isLaneSelectionStage(currentStage),
      form: createFormData(formState.laneSelectionForm, updateLaneSelectionForm),
      summary: { description: buildLaneDescription(formState.laneSelectionForm, lanes, hubs) },
      initParameters: laneSelectionInitParams,
      actions: createStageNavigation(NominationStage.LaneSelection, setStage),
    },
    [NominationStage.VesselSelection]: {
      isSelected: isVesselSelectionStage(currentStage),
      form: createFormData(formState.vesselSelectionForm, updateVesselSelectionForm),
      summary: {
        description: buildVesselDescription(
          formState.vesselSelectionForm.boatId,
          formState.vesselSelectionForm.hasTurnboat,
          boats
        ),
      },
      initParameters: vesselSelectionInitParams,
      actions: createStageNavigation(NominationStage.VesselSelection, setStage),
    },
    [NominationStage.DepartureTimeSelection]: {
      isSelected: isDepartureTimeSelectionStage(currentStage),
      form: createFormData(formState.departureTimeSelectionForm, values =>
        setFormState(prev => ({ ...prev, departureTimeSelectionForm: values }))
      ),
      summary: {},
      actions: createStageNavigation(NominationStage.DepartureTimeSelection, setStage),
    },
    [NominationStage.PoolFiltersSelection]: {
      isSelected: isPoolFiltersSelectionStage(currentStage),
      form: createFormData(formState.poolFiltersSelectionForm, values =>
        setFormState(prev => ({ ...prev, poolFiltersSelectionForm: values }))
      ),
      summary: {},
      actions: createStageNavigation(NominationStage.PoolFiltersSelection, setStage),
    },
    [NominationStage.TowParametersSelection]: {
      isSelected: isTowParametersSelectionStage(currentStage),
      form: createFormData(formState.towParametersSelectionForm, values =>
        setFormState(prev => ({ ...prev, towParametersSelectionForm: values }))
      ),
      summary: {},
      actions: createStageNavigation(NominationStage.TowParametersSelection, setStage),
    },
  }

  return {
    currentStage,
    stages,
    bargePool,
  }
}

export default useNominationFormViewModel
