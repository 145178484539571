import { useNominationsQuery } from '../generated/graphql'

import { convertNomination } from './converters'

import type { NominationData } from './models'

export type NominationsModel = {
  fetching: boolean
  nominations: NominationData[]
}

const useNominationsModel = (): NominationsModel => {
  const [{ fetching, data }] = useNominationsQuery()

  const nominations: NominationData[] = data?.nominations.map(convertNomination) || []

  return {
    fetching,
    nominations,
  }
}

export default useNominationsModel
