import React, { type FC } from 'react'

import LabelValuePair from './LabelValuePair'
import styles from './styles.module.scss'

import type { NominationSummary } from './viewModel'

const NominationSummaryBlock: FC<{ summary: NominationSummary }> = ({ summary }) => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <h2>{summary.title}</h2>
        <LabelValuePair label="Nomination time" value={summary.recordTime} />
      </div>
    </div>
  )
}

export default NominationSummaryBlock
