import React from 'react'

import { Popover } from '@headlessui/react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import { GroupByType } from '../../ui/Table/TableColumnConfig'

import styles from './GroupBySelector.module.scss'

type GroupBySelectorProps = {
  groupBy: string
  groupByKeys: string[]
  handleGroupByChange: (key: GroupByType) => void
}

const GroupBySelector: React.FC<GroupBySelectorProps> = ({ groupBy, groupByKeys, handleGroupByChange }) => {
  return (
    <div className={styles.groupByContainer}>
      <span>Group by </span>
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button className={styles.button}>{open ? <ArrowDropUp /> : <ArrowDropDown />}</Popover.Button>
            <Popover.Panel className={styles.popoverPanel}>
              <Tooltip title="Grouping table columns" placement="top">
                <div className={styles.groupBySelector}>
                  {groupByKeys.map(key => (
                    <div key={key} className={styles.groupByOption}>
                      <input
                        type="radio"
                        id={`group-by-${key}`}
                        checked={groupBy === key}
                        onChange={() => handleGroupByChange(key as GroupByType)}
                        className={styles.radio}
                        name="group-by"
                      />
                      <label htmlFor={`group-by-${key}`} className={styles.groupByLabel}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </label>
                    </div>
                  ))}
                </div>
              </Tooltip>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

export default GroupBySelector
