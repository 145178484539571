import React from 'react'

import * as R from 'ramda'

import { formatBoat } from '../../../lib/formatters'
import ComboboxSelector from '../ComboboxSelector/ComboboxSelector'

import type { NominatableBoat } from '../../../Domain/Nomination'

type BoatSelectorComboboxProps = {
  boat?: string
  boats: Record<string, NominatableBoat>
  handleChange: (item: string) => void
  isDisabled?: boolean
}

const BoatSelectorCombobox: React.FC<BoatSelectorComboboxProps> = ({
  boat,
  boats,
  handleChange,
  isDisabled = false,
}) => {
  const boatList = R.values(boats)
  const options: Record<string, { label: string }> = R.pipe(
    R.map(
      (b: NominatableBoat): Record<string, { label: string }> => ({
        [b.boatId]: { label: formatBoat(b.boatId, boatList) },
      })
    ),
    R.mergeAll<Record<string, { label: string }>>
  )(boatList)

  const displayValue = (boatId: string | undefined) => {
    return boatId ? formatBoat(boatId, boatList) : ''
  }

  return (
    <ComboboxSelector
      value={boat}
      handleChange={handleChange}
      items={options}
      displayValue={displayValue}
      placeholder="Select boat"
      IconComponent={() => null}
      isDisabled={isDisabled}
    />
  )
}

export default BoatSelectorCombobox
