import { useEffect, useState } from 'react'

import * as R from 'ramda'
import { useLocation } from 'wouter'

import { showRiverLocation } from '../../../Domain/River'
import {
  type GoalId,
  HubLike,
  HubLikeId,
  HullType,
  type LaneId,
  LoadStatus,
  type RiverLocationLite,
} from '../../../generated/graphql'
import {
  formatBoat,
  formatBoolean,
  formatHours,
  formatHubLike,
  formatOptional,
  formatPercents,
} from '../../../lib/formatters'
import useNominationModel from '../../../models/useNominationModel'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { toString } from '../../../utils/date'

import type { NominatableBoat } from '../../../Domain/Nomination'
import type {
  NominatedBarge,
  NominationData,
  NominationVersionData,
  StopsWithMetrics,
  TbnBarge,
} from '../../../models/models'

export type VersionLink = {
  readonly id: string
  readonly name: string
  readonly recordTime: string
  readonly path: string
  open: () => void
}

type Navigation = {
  readonly links: VersionLink[]
  redirectToLatest: () => void
}

export type NominationSummary = {
  readonly title: string
  readonly recordTime: string
}

type NominatedBargeStatistics = {
  readonly quantity: number
  readonly empty: number
  readonly emptyRakes: number
  readonly emptyBoxes: number
  readonly loaded: number
  readonly loadedRakes: number
  readonly loadedBoxes: number
  readonly rakes: number
  readonly boxes: number
}

type TbnBargeStatistics = {
  readonly quantity: number
  readonly entries: TbnStatisticsEntry[]
}

export type VersionSummary = {
  readonly recordTime: string
  readonly lane: string
  readonly origin: string
  readonly destination: string
  readonly expectedDepartureDate: string
  readonly operationalGoal: string
  readonly transitTime: string
  readonly dwellTime: string
  readonly vessel: string
  readonly hasTurnboat: string
  readonly totalStops: number
  readonly totalDestinations: number
  readonly towScore: string
  readonly nominatedBargeStatistics: NominatedBargeStatistics
  readonly tbnBargeStatistics: TbnBargeStatistics
}

export type Stop = {
  readonly code: string
  readonly mileagePoint: number
  readonly dropOffs: string[]
  readonly pickUps: string[]
  readonly inTowBarges: string[]
  readonly travelMinutesToNextStop: number | null
  readonly dwellMinutes: number | null
  readonly distanceToNextStop: number
}

export type JourneyData = {
  readonly stops: Stop[]
  readonly boat: string
  stopSelectionHandler: (location: RiverLocationLite | null) => void
}

export type SelectedVersion = {
  readonly summary: VersionSummary
  readonly journey: JourneyData
  readonly nominatedBarges: NominatedBarge[]
}

export type FetchingNominationDetailsViewModel = {
  readonly fetching: true
}

export type FetchedNominationDetailsViewModel = {
  readonly fetching: false
  readonly navigation: Navigation
  readonly nominationSummary: NominationSummary
  readonly selectedVersion: SelectedVersion | null
}

type NominationDetailsViewModel = FetchingNominationDetailsViewModel | FetchedNominationDetailsViewModel

export const isFetching = (ndvm: NominationDetailsViewModel): ndvm is FetchingNominationDetailsViewModel =>
  ndvm.fetching
export const isFetched = (ndvm: NominationDetailsViewModel): ndvm is FetchedNominationDetailsViewModel => !ndvm.fetching

const buildNominatedBargeStatistics = (barges: NominatedBarge[]): NominatedBargeStatistics => {
  const initialBargeStatistics: NominatedBargeStatistics = {
    quantity: 0,
    empty: 0,
    emptyRakes: 0,
    emptyBoxes: 0,
    loaded: 0,
    loadedRakes: 0,
    loadedBoxes: 0,
    rakes: 0,
    boxes: 0,
  }

  const asKey = (loadStatus: LoadStatus, hullType: HullType): string => `${loadStatus}:${hullType}`

  const lensSets: Record<string, R.Lens<NominatedBargeStatistics, number>[]> = {
    [asKey(LoadStatus.Empty, HullType.Rake)]: [R.lensProp('empty'), R.lensProp('rakes'), R.lensProp('emptyRakes')],
    [asKey(LoadStatus.Empty, HullType.Box)]: [R.lensProp('empty'), R.lensProp('boxes'), R.lensProp('emptyBoxes')],
    [asKey(LoadStatus.Loaded, HullType.Rake)]: [R.lensProp('loaded'), R.lensProp('rakes'), R.lensProp('loadedRakes')],
    [asKey(LoadStatus.Loaded, HullType.Box)]: [R.lensProp('loaded'), R.lensProp('boxes'), R.lensProp('loadedBoxes')],
  }
  const quantityLens: R.Lens<NominatedBargeStatistics, number> = R.lensProp('quantity')

  const reducer = (acc: NominatedBargeStatistics, barge: NominatedBarge) => {
    const lensesForCurrentBarge: R.Lens<NominatedBargeStatistics, number>[] =
      lensSets[asKey(barge.loadStatus, barge.hullType ?? HullType.Box)]

    if (!lensesForCurrentBarge) return acc

    const lenses: R.Lens<NominatedBargeStatistics, number>[] = R.append(
      quantityLens,
      lensSets[asKey(barge.loadStatus, barge.hullType ?? HullType.Box)]
    )
    const modifiers: ((value: NominatedBargeStatistics) => NominatedBargeStatistics)[] = R.map(
      lens => R.over(lens, R.inc),
      lenses
    )

    // @ts-ignore
    return R.pipe(...modifiers)(acc)
  }

  return R.reduce(reducer, initialBargeStatistics, barges)
}

type TbnStatisticsEntry = { [LoadStatus.Loaded]: number; [LoadStatus.Empty]: number; quantity: number; title: string }
type TbnStatisticsAggregator = Record<string, TbnStatisticsEntry>

const buildTbnStatistics = (tbnBarges: TbnBarge[]): TbnBargeStatistics => {
  const groupTitle = (pickup: string, dropOff: string) => `${pickup} → ${dropOff}`
  const reducer = (acc: TbnStatisticsAggregator, barge: TbnBarge): TbnStatisticsAggregator => {
    const pickupLabel = showRiverLocation(barge.pickupFacility)
    const dropOffLabel = showRiverLocation(barge.dropOffFacility)
    const key = `${pickupLabel}-${dropOffLabel}`

    const currentEntry = acc[key] ?? {
      [LoadStatus.Loaded]: 0,
      [LoadStatus.Empty]: 0,
      quantity: 0,
      title: groupTitle(pickupLabel, dropOffLabel),
    }
    const modifiedEntry = R.pipe(
      R.assoc(barge.expectedLoadStatus, R.inc(currentEntry[barge.expectedLoadStatus])),
      R.assoc('quantity', R.inc(currentEntry.quantity))
    )(currentEntry)

    return R.assoc(key, modifiedEntry, acc)
  }

  const entries: TbnStatisticsEntry[] = R.pipe(R.reduce(reducer, {}), R.values)(tbnBarges)
  const quantity = R.pipe(
    R.map((e: TbnStatisticsEntry) => e.quantity),
    R.sum
  )(entries)

  return {
    quantity,
    entries,
  }
}

const computeTransitTime = (stopsWithMetrics: StopsWithMetrics[]): number => {
  return (
    R.pipe(
      R.map((s: StopsWithMetrics) => s.travelMinutesToNextStop ?? 0),
      R.sum
    )(stopsWithMetrics) / 60
  )
}

const computeDwellTime = (stopsWithMetrics: StopsWithMetrics[]): number => {
  return (
    R.pipe(
      R.map((s: StopsWithMetrics) => s.dwellMinutes ?? 0),
      R.sum
    )(stopsWithMetrics) / 60
  )
}

const computeTotalDestinations = (barges: NominatedBarge[]): number => {
  const destinations = R.map(b => (b.destination ? showRiverLocation(b.destination) : null), barges)
  return new Set(destinations).size
}

const buildJourney = (stopsWithMetrics: StopsWithMetrics[], boat: string): JourneyData => {
  const stops: Stop[] = R.map(s => {
    return {
      code: s.stop.code,
      mileagePoint: s.stop.mileagePoint,
      dropOffs: R.map(b => b.id, s.bargesToDrop),
      pickUps: R.map(b => b.id, s.bargesToPickup),
      inTowBarges: [], // TODO
      travelMinutesToNextStop: s.travelMinutesToNextStop,
      dwellMinutes: s.dwellMinutes,
      distanceToNextStop: s.distanceToNextStop ?? 0,
    }
  }, stopsWithMetrics)

  return {
    boat,
    stops,
    stopSelectionHandler: (): void => {},
  }
}

const buildSelectedVersion = (
  selectedVersion: NominationVersionData,
  lanes: Record<LaneId, string>,
  goals: Record<GoalId, { label: string; description: string }>,
  hubs: Record<HubLikeId, HubLike>,
  boats: NominatableBoat[]
): SelectedVersion => {
  const { recordTime, nominationRequest, tow } = selectedVersion
  const { towConfiguration, bargeFilters } = nominationRequest

  const formattedBoatIdentity = formatBoat(towConfiguration.boat, boats)

  const summary = {
    recordTime: toString(recordTime),
    lane: lanes[bargeFilters.lane],
    origin: formatHubLike(bargeFilters.towOrigin, hubs),
    destination: formatHubLike(bargeFilters.towDestination, hubs),
    expectedDepartureDate: formatOptional(bargeFilters.expectedDepartureTime, toString),
    operationalGoal: goals[towConfiguration.goal].label,
    transitTime: formatHours(computeTransitTime(tow.stopsWithMetrics)),
    dwellTime: formatHours(computeDwellTime(tow.stopsWithMetrics)),
    vessel: formattedBoatIdentity,
    hasTurnboat: formatBoolean(towConfiguration.hasTurnboat),
    totalStops: tow.stopsWithMetrics.length,
    totalDestinations: computeTotalDestinations(tow.barges),
    towScore: formatOptional(tow.efficiencyMetric, formatPercents),
    nominatedBargeStatistics: buildNominatedBargeStatistics(tow.barges),
    tbnBargeStatistics: buildTbnStatistics(tow.tbnBarges),
  }

  return {
    summary,
    journey: buildJourney(tow.stopsWithMetrics, formattedBoatIdentity),
    nominatedBarges: selectedVersion.tow.barges,
  }
}

const buildNavigation = (
  nominationSlug: string,
  versions: NominationVersionData[],
  latestVersionId: string,
  navigate: (to: string, options?: { replace?: boolean }) => void,
  setCurrentVersionId: (versionId: string) => void
): Navigation => {
  const versionLinks = versions.map(v => {
    return {
      id: v.id,
      name: v.slug,
      recordTime: toString(v.recordTime),
      path: `/nomination/${nominationSlug}/version/${v.id}`,
      open: () => {
        setCurrentVersionId(v.id)
        navigate(`/nomination/${nominationSlug}/version/${v.id}`)
      },
    }
  })

  const redirectToLatest = () => {
    setCurrentVersionId(latestVersionId)
    navigate(`/nomination/${nominationSlug}/version/${latestVersionId}`, { replace: true })
  }

  return {
    links: versionLinks,
    redirectToLatest,
  }
}

const buildNominationSummary = (nomination: NominationData, lanes: Record<LaneId, string>): NominationSummary => {
  const { lane: laneId, origin, destination, recordTime } = nomination
  const lane = lanes[laneId]

  return {
    title: `${lane}: ${origin} to ${destination}`,
    recordTime: toString(recordTime),
  }
}

const useNominationDetailsViewModel = (slug: string, versionId: string | null): NominationDetailsViewModel => {
  const { fetching, nomination, versions } = useNominationModel(slug)

  const [, navigate] = useLocation()
  const { lanes, goals, hubs, boats } = useSettingsContext()

  const latestVersionId = R.sortBy(R.prop('recordTime'), versions)[0]?.id
  const [currentVersionId, setCurrentVersionId] = useState(versionId)
  useEffect(() => {
    if (!currentVersionId) {
      setCurrentVersionId(latestVersionId)
    }
  }, [latestVersionId, currentVersionId])

  if (!nomination || fetching) {
    return { fetching: true }
  }

  const nominationSlug = nomination?.slug
  const navigation: Navigation = buildNavigation(
    nominationSlug,
    versions,
    latestVersionId,
    navigate,
    setCurrentVersionId
  )
  const nominationSummary: NominationSummary = buildNominationSummary(nomination, lanes)

  const selectedVersionData = R.find(v => v.id === currentVersionId, versions)
  const selectedVersion = selectedVersionData
    ? buildSelectedVersion(selectedVersionData, lanes, goals, hubs, boats)
    : null

  return {
    fetching,
    navigation,
    nominationSummary,
    selectedVersion,
  }
}

export default useNominationDetailsViewModel
