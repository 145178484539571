import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js'

type AwsCognitoResponse = { user: CognitoUser; error: Error | null }
type AwsCognitoSessionResponse = { user: CognitoUser; session: CognitoUserSession; error: Error | null }

const EMAIL_LOGIN_USERID = 'email'
const FEDERATED_LOGIN_USERID = 'cognito:username'

export interface AuthorizationClient {
  currentUser(): Promise<AwsCognitoSessionResponse>

  authenticate(email: string, password: string): Promise<AwsCognitoSessionResponse>
  authenticateWithToken(
    idToken: string,
    accessToken: string,
    refreshToken: string | null
  ): Promise<AwsCognitoSessionResponse>
  logout(user: CognitoUser): Promise<any>
  forceResetPassword(user: CognitoUser, userAttributes: any, newPassword: string): Promise<AwsCognitoSessionResponse>
  forgotPassword(email: string): Promise<AwsCognitoSessionResponse>
  resetPassword(user: CognitoUser, code: string, password: string): Promise<AwsCognitoResponse>
}

export const NEW_PASSWORD_REQUIRED_ERROR = 'NEW_PASSWORD_REQUIRED'

export class AwsCognitoClient implements AuthorizationClient {
  private readonly userPool: CognitoUserPool

  constructor(poolConfiguration: ICognitoUserPoolData) {
    this.userPool = new CognitoUserPool(poolConfiguration)
  }

  currentUser() {
    return new Promise<AwsCognitoSessionResponse>((resolve, reject) => {
      const user = this.userPool.getCurrentUser()
      if (user) {
        user.getSession((error: Error | null, session: CognitoUserSession | null) => {
          if (error) {
            reject({ user, session, error })
          }

          if (session) {
            resolve({ user, session, error: null })
          }
        })
      }
    })
  }

  authenticate(email: string, password: string) {
    return new Promise<AwsCognitoSessionResponse>((resolve, reject) => {
      const user = new CognitoUser({ Username: email, Pool: this.userPool })
      const authDetails = new AuthenticationDetails({ Username: email, Password: password })

      user.authenticateUser(authDetails, {
        onSuccess: (session: CognitoUserSession) => {
          resolve({ user, session, error: null })
        },
        onFailure: error => {
          reject({ user, session: null, error })
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          reject({
            user,
            session: null,
            error: { type: NEW_PASSWORD_REQUIRED_ERROR, userAttributes, requiredAttributes },
          })
        },
      })
    })
  }

  authenticateWithToken(idToken: string, accessToken: string, refreshToken: string | null) {
    return new Promise<AwsCognitoSessionResponse>((resolve, reject) => {
      try {
        const parsedIdToken = new CognitoIdToken({ IdToken: idToken })
        const parameters = {
          IdToken: parsedIdToken,
          AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken || '' }),
        }
        const session = new CognitoUserSession(parameters)
        const idTokenPayload = parsedIdToken.decodePayload()
        const username = idTokenPayload[EMAIL_LOGIN_USERID] ?? idTokenPayload[FEDERATED_LOGIN_USERID]
        const user = new CognitoUser({ Username: username, Pool: this.userPool })

        if (session.isValid()) {
          user.setSignInUserSession(session)
          resolve({ user, session, error: null })
        } else {
          reject({ user, session: null, error: new Error('Failed to authenticate using external UI') })
        }
      } catch (err) {
        reject({ user: null, session: null, error: err })
      }
    })
  }

  logout(user: CognitoUser) {
    return new Promise<any>(resolve => {
      user.signOut(() => resolve(null))
    })
  }

  forceResetPassword(user: CognitoUser, userAttributes: any, newPassword: string) {
    return new Promise<AwsCognitoSessionResponse>((resolve, reject) => {
      delete userAttributes.email_verified
      delete userAttributes.email

      user.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (session: CognitoUserSession) => {
          resolve({ user, session, error: null })
        },
        onFailure: error => {
          reject({ user, session: null, error })
        },
      })
    })
  }

  forgotPassword(email: string) {
    return new Promise<AwsCognitoSessionResponse>((resolve, reject) => {
      const user = new CognitoUser({ Username: email, Pool: this.userPool })

      user.forgotPassword({
        onSuccess: (session: CognitoUserSession) => {
          resolve({ user, session, error: null })
        },
        onFailure: error => {
          reject({ user, session: null, error })
        },
      })
    })
  }

  resetPassword(user: CognitoUser, code: string, password: string) {
    return new Promise<AwsCognitoResponse>((resolve, reject) => {
      user.confirmPassword(code, password, {
        onSuccess: () => {
          resolve({ user, error: null })
        },
        onFailure: error => {
          reject({ user, error })
        },
      })
    })
  }
}
