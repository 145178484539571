import { useCallback } from 'react'

import { Filter } from '../../../Domain/BargeFilters'
import { isHub, lanesOriginDestFilteringRecord } from '../../../Domain/Hub'
import { isLane } from '../../../Domain/Lane'
import { HubLike } from '../../../generated/graphql'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { HubLikeSelector } from '../HubLikeSelector/HubLikeSelector'
import { LaneSelectorCombobox } from '../LaneSelector/LaneSelector'

import styles from './LaneFilters.module.scss'
import { ColumnEntry } from './Layout'

type Props = {
  filters: Filter
  setFilters: (filters: Filter) => void
  readOnly: boolean
}

export function LocationFilter({ filters, setFilters, readOnly }: Props) {
  const { lane, origin, destination } = filters
  const originFilter = useCallback(
    (hub: HubLike) => lane !== undefined && Object.keys(lanesOriginDestFilteringRecord[lane]).includes(hub.id),
    [lane]
  )

  const destinationFilter = useCallback(
    (hub: HubLike) => isHub(origin) && (lanesOriginDestFilteringRecord[lane!][origin] ?? []).includes(hub.id),
    [lane, origin]
  )

  const { lanes } = useSettingsContext()

  return (
    <>
      <ColumnEntry columnClassnames={readOnly ? [styles.disabledEntry] : undefined}>
        <span>Lane</span>
        <span className={styles.select}>
          <LaneSelectorCombobox
            lane={lane}
            lanes={lanes}
            handleChange={item => {
              if (isLane(item)) {
                setFilters({ ...filters, lane: item, origin: undefined, destination: undefined })
              }
            }}
          />
        </span>
      </ColumnEntry>
      <ColumnEntry columnClassnames={readOnly ? [styles.disabledEntry] : undefined}>
        <span>Origin</span>
        <span className={styles.select}>
          <HubLikeSelector
            isDisabled={lane === undefined}
            value={origin}
            handleChange={originChange => setFilters({ ...filters, origin: originChange, destination: undefined })}
            placeholder="Select origin"
            filter={originFilter}
            readOnly={readOnly}
          />
        </span>
      </ColumnEntry>
      <div className={readOnly ? styles.disabledEntry : ''}>
        <ColumnEntry>
          <span>Destination</span>
          <span className={styles.select}>
            <HubLikeSelector
              isDisabled={origin === undefined}
              value={destination}
              handleChange={destinationChange => setFilters({ ...filters, destination: destinationChange })}
              placeholder="Select destination"
              filter={destinationFilter}
              readOnly={readOnly}
            />
          </span>
        </ColumnEntry>
      </div>
    </>
  )
}
