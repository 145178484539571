import * as R from 'ramda'

import { useNominationBySlugQuery } from '../generated/graphql'

import { convertNomination, convertNominationVersion } from './converters'

import type { NominationData, NominationVersionData } from './models'

type NominationDetailsModel = {
  fetching: boolean
  nomination: NominationData | null
  versions: NominationVersionData[]
}

const useNominationsModel = (slug: string): NominationDetailsModel => {
  const [{ fetching, data }] = useNominationBySlugQuery({
    variables: { slug },
  })

  const fetchedNomination = data?.nominationBySlug?.nomination
  const nomination = fetchedNomination ? convertNomination(fetchedNomination) : null

  const fetchedVersions = data?.nominationBySlug?.versions || []

  const versions = R.map(convertNominationVersion, fetchedVersions)

  return {
    fetching,
    nomination,
    versions,
  }
}

export default useNominationsModel
