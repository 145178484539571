import { PropsWithChildren } from 'react'

import { ArrowBack } from '@mui/icons-material'
import classnames from 'classnames'

import styles from './SortColumn.module.scss'

export function SortableColumn({
  isSorted,
  handleToggleSorting,
  children,
}: PropsWithChildren<{ isSorted?: 'asc' | 'desc'; handleToggleSorting: () => void }>) {
  return (
    <div className={styles.column}>
      {children}
      <button onClick={() => handleToggleSorting()}>
        <ArrowBack
          className={classnames(styles.sortArrow, {
            [styles.active]: isSorted !== undefined,
            [styles.desc]: isSorted === 'desc',
          })}
        />
      </button>
    </div>
  )
}
