import { useLocation } from 'wouter'

import { showRiverLocation } from '../../../Domain/River'
import useNominationsModel from '../../../models/useNominationsModel'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { toString } from '../../../utils/date'

import type { NominationData } from '../../../models/models'

export interface NominationCard {
  id: string
  slug: string
  title: string
  recordTime: string

  open: () => void
}

export interface NominationsViewModel {
  fetching: boolean
  nominations: NominationCard[]
  newNomination: () => void
}

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

function formatDate(value: Date) {
  return toString(value, DATE_FORMAT)
}

function createNominationCard(
  nomination: NominationData,
  lane: string,
  origin: string,
  destination: string,
  navigate: () => void
): NominationCard {
  return {
    id: nomination.id,
    slug: nomination.slug,
    title: `${lane}: ${origin} to ${destination}`,
    recordTime: formatDate(nomination.recordTime),
    open: navigate,
  }
}

const useNominationsViewModel = (): NominationsViewModel => {
  const { fetching, nominations } = useNominationsModel()

  const [, navigate] = useLocation()
  const { lanes, hubs } = useSettingsContext()

  const nominationCards: NominationCard[] = nominations.map(nomination => {
    const lane = lanes[nomination.lane]
    const origin = showRiverLocation(hubs[nomination.origin].riverLocation)
    const destination = showRiverLocation(hubs[nomination.destination].riverLocation)
    const navigationHandler = () => navigate(`/nomination/${nomination.slug}`)
    return createNominationCard(nomination, lane, origin, destination, navigationHandler)
  })

  const newNomination = () => navigate('/nominations/new')

  return {
    fetching,
    newNomination,
    nominations: nominationCards,
  }
}

export default useNominationsViewModel
