import React, { type FC } from 'react'

import classnames from 'classnames'

import { LoadStatus } from '../../../generated/graphql'

import LabelValuePair from './LabelValuePair'
import styles from './styles.module.scss'

import type { VersionSummary } from './viewModel'

const NominationVersionSummary: FC<{ summary: VersionSummary }> = ({ summary }) => {
  const { nominatedBargeStatistics, tbnBargeStatistics } = summary

  return (
    <div className={styles.container}>
      <div className={classnames([styles.section, styles.columns])}>
        <div className={styles.column}>
          <LabelValuePair label="Version nomination time" value={summary.recordTime} />
          <LabelValuePair label="Lane" value={summary.lane} />
          <LabelValuePair label="Origin" value={summary.origin} />
          <LabelValuePair label="Destination" value={summary.destination} />
          <LabelValuePair label="Ops. goal" value={summary.operationalGoal} />
          <LabelValuePair label="Departure time" value={summary.expectedDepartureDate} />
        </div>
        <div className={styles.column}>
          <LabelValuePair label="Vessel" value={summary.vessel} />
          <LabelValuePair label="Turnboat" value={summary.hasTurnboat} />
          <LabelValuePair label="Tot. transit time" value={summary.transitTime} />
          <LabelValuePair label="Tot. dwell time" value={summary.dwellTime} />
          <LabelValuePair label="Total stops" value={summary.totalStops} />
          <LabelValuePair label="Total destinations" value={summary.totalDestinations} />
          <LabelValuePair label="Tow score" value={summary.towScore} />
        </div>
        <div className={styles.column}>
          <LabelValuePair label="Total barges" value={nominatedBargeStatistics.quantity} />
          <LabelValuePair label="Loaded" value={nominatedBargeStatistics.loaded} labelStyle={styles.padded} />
          <LabelValuePair label="Empty" value={nominatedBargeStatistics.empty} labelStyle={styles.padded} />
          <LabelValuePair label="Total rakes" value={nominatedBargeStatistics.rakes} />
          <LabelValuePair label="Loaded" value={nominatedBargeStatistics.loadedRakes} labelStyle={styles.padded} />
          <LabelValuePair label="Empty" value={nominatedBargeStatistics.loadedBoxes} labelStyle={styles.padded} />
          <LabelValuePair label="Total boxes" value={nominatedBargeStatistics.boxes} />
          <LabelValuePair label="Loaded" value={nominatedBargeStatistics.loadedBoxes} labelStyle={styles.padded} />
          <LabelValuePair label="Empty" value={nominatedBargeStatistics.emptyBoxes} labelStyle={styles.padded} />
        </div>
        <div className={styles.column}>
          <LabelValuePair label="TBN barges" value={tbnBargeStatistics.quantity} />
          {tbnBargeStatistics.entries.map(entry => (
            <div key={entry.title}>
              <LabelValuePair label={entry.title} value={entry.quantity} />
              <LabelValuePair label="Loaded" value={entry[LoadStatus.Loaded]} labelStyle={styles.padded} />
              <LabelValuePair label="Empty" value={entry[LoadStatus.Empty]} labelStyle={styles.padded} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NominationVersionSummary
