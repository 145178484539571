import { format, utcToZonedTime } from 'date-fns-tz'

const defaultTimezone = 'US/Central'
const formatString = "yyyy-MM-dd'T'HH:mm:ss.SSxxx"

const defaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

export const toString = (date: Date, options = defaultDateTimeFormatOptions, timeZone = defaultTimezone) =>
  Intl.DateTimeFormat('default', { ...options, timeZone }).format(date)

export const durationComponentsFromSeconds = (nr: number) => ({
  week: Math.floor(nr / (86400 * 7)),
  day: Math.floor(nr / 86400) % 7,
  hour: Math.floor(nr / 3600) % 24,
  minute: nr % 60,
})

export const durationComponentsFromMinutes = (nr: number) => ({
  day: Math.floor(nr / 3600),
  hour: Math.floor(nr / 60) % 24,
  minute: nr % 60,
})

export const isoZonedDateTime = (d?: Date | number): string | null => {
  return d ? format(d, formatString, { timeZone: defaultTimezone }) : null
}

export const getTimeFromExpectedDeparture = (d?: Date): number | undefined => {
  return d ? utcToZonedTime(d, defaultTimezone).getTime() : undefined
}
