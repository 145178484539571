import React, { type FC } from 'react'

import FormOrSummary from './FormOrSummary'

import type { StageData } from './types'

const PoolFiltersSelectionForm: FC<{}> = () => {
  return <div>Filters form</div>
}

const PoolFiltersSelectionSummary: FC<{}> = () => {
  return <div>Not set</div>
}

const PoolFiltersSelectionBlock: FC<StageData<any, any, any>> = ({ isSelected, actions }) => {
  return (
    <FormOrSummary
      title="Filters"
      isSelected={isSelected}
      actions={actions}
      form={<PoolFiltersSelectionForm />}
      summary={<PoolFiltersSelectionSummary />}
    />
  )
}

export default PoolFiltersSelectionBlock
