import { HubLikeId, LaneId } from '../generated/graphql'
import { isNumeric } from '../utils/number'

import { isHub } from './Hub'
import { isLane } from './Lane'

export type Filter = {
  lane?: LaneId
  origin?: HubLikeId
  destination?: HubLikeId
  excludeBargeIds: string[]
  excludeNominatedBarges: boolean
  excludeTanks: boolean
  excludeHoppers: boolean
  excludeTboInfo: boolean
  excludePlacedToLoad: boolean
  maxDraft?: number
  time?: number
}

export function searchParamsToFilter(search: string): Filter {
  const searchParams = new URLSearchParams(search)

  const lane = searchParams.get('lane')
  const origin = searchParams.get('origin')
  const destination = searchParams.get('destination')
  const maxDraft = searchParams.get('maxDraft')
  const time = searchParams.get('time')

  return {
    lane: isLane(lane) ? lane : undefined,
    origin: isHub(origin) ? origin : undefined,
    destination: isHub(destination) ? destination : undefined,
    excludeBargeIds: [],
    excludeNominatedBarges: searchParams.get('excludeNominatedBarges') === 'true',
    excludeTanks: searchParams.get('excludeTanks') === 'true',
    excludeHoppers: searchParams.get('excludeHoppers') === 'true',
    excludeTboInfo: searchParams.get('excludeTboInfo') === 'true',
    excludePlacedToLoad: searchParams.get('excludePlacedToLoad') === 'true',
    maxDraft: isNumeric(maxDraft ?? '') ? Number(maxDraft) : undefined,
    time: isNumeric(time ?? '') ? Number(time) : undefined,
  }
}
