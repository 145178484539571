import { PlaceOutlined } from '@mui/icons-material'

import { LaneId } from '../../../generated/graphql'
import ComboboxSelector from '../ComboboxSelector/ComboboxSelector'

function isLaneId(key: any, lanes: Record<LaneId, string>): key is LaneId {
  return key in lanes
}

export function LaneSelectorCombobox({
  lane,
  lanes,
  handleChange,
}: {
  lane?: string
  lanes: Record<LaneId, string>
  handleChange: (item: string) => void
}) {
  return (
    <ComboboxSelector
      value={lane}
      handleChange={handleChange}
      items={lanes}
      displayValue={(item: string | undefined) => {
        return item && isLaneId(item, lanes) ? lanes[item] : ''
      }}
      placeholder="Select lane"
      IconComponent={PlaceOutlined}
    />
  )
}
