import { HubLikeId, LaneId, NonHub } from '../../generated/graphql'

import json from './lanes_origin_dest_filtering_v6.1.json'

export const lanesOriginDestFilteringRecord: Record<LaneId, { [key in HubLikeId]?: string[] }> = json

// {N|S|E|W}B =  North | South | East | West - bound

export const isNB = (lane: LaneId) =>
  ['NBUnlocked', 'NBUnlockedDirect', 'NBUMR', 'NBIllinois', 'NBunlockedDirectSTL'].includes(lane)

const HubLikeIds = Object.values(HubLikeId)

export const isHub = (hub: any): hub is HubLikeId => HubLikeIds.includes(hub)

// eslint-disable-next-line no-underscore-dangle
export const isNonHub = (hub: any): hub is NonHub => '__typename' in hub && hub.__typename === 'NonHub'
