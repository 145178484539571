import { Warning } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { match } from 'ts-pattern'

import { ELLIPSIS } from '../../constants/constants'
import { isRiskLevel } from '../../Domain/Barge'
import { RiskLevel } from '../../generated/graphql'

import styles from './Table.module.scss'

export function getRiskLevelColor(risk: RiskLevel) {
  switch (risk) {
    case RiskLevel.HighRisk: {
      return '#db392a'
    }
    case RiskLevel.MediumRisk: {
      return '#f39900'
    }

    default: {
      return '#ffd800'
    }
  }
}

export const showRiskLevel = (risk: RiskLevel) =>
  match(risk)
    .with(RiskLevel.HighRisk, () => 'High risk')
    .with(RiskLevel.MediumRisk, () => 'Medium risk')
    .with(RiskLevel.LowRisk, () => 'Low risk')
    .with(RiskLevel.NoRisk, () => 'No risk')
    .exhaustive()

export const RiskLevelEntry = ({ risk }: { risk?: string | null }) => (
  <span className={styles.isAtRisk}>
    {isRiskLevel(risk) && risk !== RiskLevel.NoRisk ? (
      <span style={{ color: getRiskLevelColor(risk) }}>
        <Tooltip title={showRiskLevel(risk)}>
          <Warning />
        </Tooltip>
      </span>
    ) : (
      ELLIPSIS
    )}
  </span>
)
