import React, { type FC } from 'react'

import FormOrSummary from './FormOrSummary'

import type { StageData } from './types'

const TowParametersSelectionForm: FC<{}> = () => {
  return <div>Tow parameters form</div>
}

const TowParametersSelectionSummary: FC<{}> = () => {
  return <div>Not set</div>
}

const TowParametersSelectionBlock: FC<StageData<any, any, any>> = ({ isSelected, actions }) => {
  return (
    <FormOrSummary
      title="Tow parameters"
      isSelected={isSelected}
      actions={actions}
      form={<TowParametersSelectionForm />}
      summary={<TowParametersSelectionSummary />}
    />
  )
}

export default TowParametersSelectionBlock
