import { useCallback, useState, useMemo, PropsWithChildren } from 'react'

import { ArrowBack, PushPin, TableChart, ViewCompact } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import classnames from 'classnames'
import { Form } from 'formik'
import * as R from 'ramda'

import { ELLIPSIS } from '../../../constants/constants'
import { fromBargesQuery, isEmpty, OverviewBarge } from '../../../Domain/Barge'
import { Filter } from '../../../Domain/BargeFilters'
import { getExcludedBargeTypes } from '../../../Domain/BargeType'
import { fromGqlUserBargeNomination, fromRequestFilters, UserBargeNomination } from '../../../Domain/Nomination'
import { excludePlacedToLoadStatuses } from '../../../Domain/Trip'
import { HubLikeId, HullType, LaneId, TowConfiguration, useLaneBargesQuery } from '../../../generated/graphql'
import { useAuthorizationContext } from '../../../Pages/Account/AuthorizationContext'
import { useBargeSelectionBridge } from '../../../providers/BargeSelectionBridge'
import { useLatestNominationContext } from '../../../providers/LatestNominationProvider'
import { CloseButton } from '../../../ui/CloseButton/CloseButton'
import { WarningMessage } from '../../../ui/MessageBox/MessageBox'
import { Spinner } from '../../../ui/Spinner/Spinner'
import { collectFeedbackData } from '../../../utils/collectFeedbackData'
import { isoZonedDateTime } from '../../../utils/date'
import { BargeListing } from '../../BargeListing/BargeListing'
import { Filters } from '../../Header/LaneFilters/LaneFilters'
import { NominationForm, useNominationFormContext, Values } from '../NominationForm'
import { HotBargeSection } from '../NominationForm/HotBargesSection'
import { PreselectedBargesSection } from '../NominationForm/PreselectedBargesSection'
import { initialConfig, TowConfigSection, TowNominationFormInput } from '../NominationForm/TowConfigurationSection'

import styles from './NominateBarges.module.scss'

type Props = {
  nomination?: UserBargeNomination
  handleClose: () => void
  setNominationData?: (data: { nomination: UserBargeNomination; barges: OverviewBarge[] }) => void
  readOnly: boolean
}

function concatUnique<T>(left: T[], right: T[]): T[] {
  return R.uniq(R.concat(left, right))
}

export function NominateBarges({ nomination: initialNomination, handleClose, setNominationData, readOnly }: Props) {
  const { getUserInfo } = useAuthorizationContext()

  const { preSelectedBarges, preExcludedBarges, clearPreSelectionAndExclusion } = useBargeSelectionBridge()

  const [pinnedBarges, setPinnedBarges] = useState<string[]>(
    concatUnique(initialNomination?.userRequest.towConfiguration.preselectedBarges ?? [], preSelectedBarges)
  )

  const [excludedBarges, setExcludedBarges] = useState<string[]>(
    concatUnique(initialNomination?.userRequest.bargeFilters.excludeBargeIds ?? [], preExcludedBarges)
  )

  const { nominate: nominateBarges } = useLatestNominationContext()

  const [isPinnedBargesVisible, setIsPinnedBargesVisible] = useState(false)
  const [resultWarning, setResultWarning] = useState<{ title: string; message: string }>()
  const [isBargePoolVisible, setIsBargePoolVisible] = useState(false)

  const [filters, setFilters] = useState<Filter>(
    initialNomination
      ? fromRequestFilters({
          ...initialNomination.userRequest.bargeFilters,
          excludeBargeIds: concatUnique(initialNomination.userRequest.bargeFilters.excludeBargeIds, preExcludedBarges),
        })
      : {
          excludeBargeIds: [],
          excludeNominatedBarges: false,
          excludeTanks: false,
          excludeHoppers: false,
          excludeTboInfo: true,
          excludePlacedToLoad: false,
        }
  )

  const [{ data, fetching }] = useLaneBargesQuery({
    pause: filters.lane === undefined,
    variables: {
      laneId: filters.lane ?? null,
      originId: filters.origin ?? null,
      destinationId: filters.destination ?? null,
      excludeNominatedBarges: filters.excludeNominatedBarges,
      excludeBargeTypes: getExcludedBargeTypes(filters.excludeTanks, filters.excludeHoppers),
      excludeTripStatuses: filters.excludePlacedToLoad ? excludePlacedToLoadStatuses : null,
      excludeTboInfoBarges: filters.excludeTboInfo,
      maxDraft: filters.maxDraft ?? null,
      time: filters.time ? isoZonedDateTime(filters.time) : null,
    },
  })

  const handleBargePinning = useCallback(
    (bargeIds: string[]) => {
      setPinnedBarges(bargeIds)

      const intersectionWithExcluded = excludedBarges.filter(barge => bargeIds.includes(barge))
      if (intersectionWithExcluded.length > 0) {
        const updatedExclusions = excludedBarges.filter(barge => !intersectionWithExcluded.includes(barge))
        setExcludedBarges(updatedExclusions)
        setFilters({ ...filters, excludeBargeIds: updatedExclusions })
      }
    },
    [setPinnedBarges, excludedBarges, setExcludedBarges, setFilters, filters]
  )

  const handleBargeExclusion = useCallback(
    (bargeIds: string[]) => {
      setExcludedBarges(bargeIds)
      setFilters({ ...filters, excludeBargeIds: bargeIds })

      const intersectionWithPinned = pinnedBarges.filter(barge => bargeIds.includes(barge))
      if (intersectionWithPinned.length > 0) {
        setPinnedBarges(pinnedBarges.filter(barge => !intersectionWithPinned.includes(barge)))
      }
    },
    [setFilters, setExcludedBarges, filters, pinnedBarges, setPinnedBarges]
  )

  const barges = useMemo(() => (data === undefined ? undefined : fromBargesQuery(data.lanes[0].barges)), [data])

  function getTowConfiguration(
    pinnedBargesParam: string[],
    initialConfigParam: TowNominationFormInput,
    nomination?: UserBargeNomination
  ): TowNominationFormInput {
    return nomination?.userRequest.towConfiguration
      ? {
          ...nomination.userRequest.towConfiguration,
          numberOfLoaded:
            nomination.userRequest.towConfiguration.numberOfBarges -
            nomination.userRequest.towConfiguration.numberOfEmptyBarges,
          numberOfEmpties: nomination.userRequest.towConfiguration.numberOfEmptyBarges,
          numberOfStrings: nomination.userRequest.towConfiguration.numberOfStrings,
          preselectedBarges: pinnedBargesParam,
        }
      : {
          ...initialConfigParam,
          numberOfLoaded: initialConfigParam.numberOfLoaded,
          numberOfEmpties: initialConfigParam.numberOfEmpties,
          numberOfStrings: initialConfigParam.numberOfStrings,
          preselectedBarges: pinnedBargesParam,
        }
  }

  const convertToTowConfiguration = (input: TowNominationFormInput): TowConfiguration => ({
    goal: input.goal!,
    numberOfBarges: input.numberOfLoaded + input.numberOfEmpties,
    numberOfEmptyBarges: input.numberOfEmpties,
    numberOfStrings: input.numberOfStrings,
    preselectedBarges: input.preselectedBarges,
    boat: input.boat,
    hasTurnboat: input.hasTurnboat ?? false,
  })

  const exportFeedbackData = useCallback(
    ({ towConfiguration: towConfigurationInput, prioritizeHotBarges }: Values) => {
      const userInfo = getUserInfo()

      if (userInfo && filters.lane && towConfigurationInput && towConfigurationInput.goal) {
        const towConfigInput: TowConfiguration = convertToTowConfiguration(towConfigurationInput)

        collectFeedbackData(
          userInfo,
          filters.lane,
          filters,
          {
            prioritizeHotBarges,
            towConfiguration: towConfigInput,
          },
          [],
          barges ?? []
        )
      }
    },
    [getUserInfo, filters, barges]
  )

  const { origin, destination } = filters

  const canNominate = useMemo(
    () => [barges, origin, destination].every(v => v !== undefined),
    [barges, origin, destination]
  )

  const isSelectable = useCallback(() => canNominate, [canNominate])

  const setNomination = useCallback(
    (nomination: UserBargeNomination) => {
      if (setNominationData) {
        setNominationData({ barges: barges ?? [], nomination })
      }
    },
    [barges, setNominationData]
  )
  const handleSubmit = useCallback(
    async (values: Values) => {
      const towConfigurationInput = convertToTowConfiguration(values.towConfiguration)

      const res = await nominateBarges({
        lane: filters.lane!,
        origin: filters.origin!,
        destination: filters.destination!,
        excludeBargeIds: filters.excludeBargeIds,
        excludeTboInfoBarges: filters.excludeTboInfo,
        excludeBargeTypes: getExcludedBargeTypes(filters.excludeTanks, filters.excludeHoppers),
        excludeNominatedBarges: filters.excludeNominatedBarges,
        excludeTripStatuses: filters.excludePlacedToLoad ? excludePlacedToLoadStatuses : [],
        maximumDraft: filters.maxDraft ?? null,
        expectedDepartureTime: filters.time ? isoZonedDateTime(filters.time) : null,
        prioritizeHotBarges: values.prioritizeHotBarges,
        towConfiguration: towConfigurationInput,
      })

      if (res.data) {
        if (res.data.createBargeNomination.__typename === 'NominationCreateFailure') {
          setResultWarning({
            title: res.data.createBargeNomination.message,
            message: res.data.createBargeNomination.errors.join('\n'),
          })
        }

        if (res.data.createBargeNomination.__typename === 'NominationCreateSuccess') {
          const { nomination } = res.data.createBargeNomination

          const userRequest = {
            ...nomination.userRequest,
            towConfiguration: nomination.userRequest.towConfiguration!,
          }
          const updatedNomination = {
            ...nomination,
            userRequest,
          }
          clearPreSelectionAndExclusion()
          setNomination(fromGqlUserBargeNomination(updatedNomination))
        }
      }

      if (res.error) {
        setResultWarning({
          title: res.error.graphQLErrors.map(e => e.originalError?.message).join('\n'),
          message: 'Unexpected error, please try again with different parameters',
        })
      }
    },
    [filters, nominateBarges, setNomination, clearPreSelectionAndExclusion]
  )

  return (
    <NominationForm
      prioritizeHotBarges={initialNomination?.userRequest?.prioritizeHotBarges ?? true}
      towConfiguration={getTowConfiguration(pinnedBarges, initialConfig, initialNomination)}
      preselectedBarges={[]}
      handleSubmit={handleSubmit}>
      <div className={classnames(styles.panel, { [styles.isOverflowHidden]: isBargePoolVisible })}>
        <CloseButton handleClose={handleClose} theme={{ button: styles.closeButton }} />
        <h2 className={styles.title}>Create nomination plan</h2>
        <div className={styles.bargePool}>
          {barges && isBargePoolVisible ? (
            <BargeListing
              isSelectable={isSelectable}
              barges={barges}
              pinnedBarges={pinnedBarges}
              setPinnedBarges={handleBargePinning}
              excludedBarges={excludedBarges}
              setExcludedBarges={handleBargeExclusion}
              lane={filters.lane}
            />
          ) : null}
        </div>
        <div className={classnames(styles.content, { [styles.isCollapsed]: isBargePoolVisible })}>
          <div className={styles.columns}>
            <Filters isEnabled={canNominate} filters={filters} setFilters={setFilters} readOnly={readOnly} />
            <hr />
            <div className={styles.statsContainer}>
              <div
                className={classnames(styles.statsContent, {
                  [styles.isPinnedBargesVisible]: isPinnedBargesVisible,
                })}>
                <BargesStats
                  isFetching={fetching}
                  isSelectable={canNominate}
                  barges={barges}
                  pinnedBarges={pinnedBarges}
                  excludedBarges={excludedBarges}
                  isBargePoolVisible={isBargePoolVisible}
                  toggleIsBargePoolVisisble={() => setIsBargePoolVisible(!isBargePoolVisible)}
                  toggleIsPinnedBargesVisible={() => setIsPinnedBargesVisible(true)}
                />
                <PinnedBarges
                  barges={barges}
                  pinnedBarges={pinnedBarges}
                  hide={() => setIsPinnedBargesVisible(false)}
                />
              </div>
            </div>
            <hr />
            <NominationFormColumn
              hasTimeWindow={filters.time !== undefined}
              isDisabled={!canNominate || readOnly}
              destination={filters.destination}
              lane={filters.lane}
              barges={barges}>
              {resultWarning ? (
                <ResultWarning
                  title={resultWarning.title}
                  message={resultWarning.message}
                  handleExportFeedback={exportFeedbackData}
                  handleClose={() => setResultWarning(undefined)}
                />
              ) : null}
            </NominationFormColumn>
          </div>
          <Footer isDisabled={!canNominate || readOnly} />
        </div>
      </div>
    </NominationForm>
  )
}

function ResultWarning({
  title,
  message,
  handleClose,
  handleExportFeedback,
}: {
  title: string
  message: string
  handleExportFeedback: (values: Values) => void
  handleClose: () => void
}) {
  const { values } = useNominationFormContext()

  const feedbackExportCallback = useCallback(() => {
    handleExportFeedback(values)
  }, [values, handleExportFeedback])

  return (
    <WarningMessage className={styles.warningBox} title={title} handleClose={handleClose}>
      <span className={styles.warningMessage}>
        {message}
        <button onClick={feedbackExportCallback} type="button" className={styles.exportButton}>
          Export feedback data
        </button>
      </span>
    </WarningMessage>
  )
}

function NominationFormColumn({
  barges,
  destination,
  isDisabled,
  hasTimeWindow,
  children,
  lane,
}: PropsWithChildren<{
  barges?: OverviewBarge[]
  destination?: HubLikeId
  isDisabled: boolean
  hasTimeWindow: boolean
  lane?: LaneId
}>) {
  const { values, setFieldValue, touched, errors } = useNominationFormContext()

  return (
    <Form className={classnames(styles.form, { [styles.isDisabled]: isDisabled })}>
      <h3 className={styles.formTitle}>Nomination</h3>
      <HotBargeSection
        isDisabled={isDisabled}
        barges={barges ?? []}
        prioritizeHotBarges={values.prioritizeHotBarges}
        setPrioritizeHotBarges={val => setFieldValue('prioritizeHotBarges', val)}
      />
      <TowConfigSection
        hasTimeWindow={hasTimeWindow}
        isDisabled={isDisabled}
        barges={barges ?? []}
        destination={destination}
        touched={touched?.towConfiguration}
        errors={errors?.towConfiguration}
        towConfiguration={values.towConfiguration}
        setTowConfiguration={val => setFieldValue('towConfiguration', val)}
        lane={lane}
      />
      {children}
    </Form>
  )
}

function Footer({ isDisabled }: { isDisabled: boolean }) {
  const { submitForm, isSubmitting, setSubmitting } = useNominationFormContext()

  return (
    <footer className={styles.footer}>
      <button
        disabled={isDisabled}
        type="submit"
        onClick={() => {
          submitForm().then(() => setSubmitting(false))
          setSubmitting(true)
        }}>
        {isSubmitting ? <Spinner className={styles.spinner} /> : null}
        Create nomination
      </button>
    </footer>
  )
}

function PinnedBarges({
  hide,
  barges,
  pinnedBarges,
}: {
  barges?: OverviewBarge[]
  pinnedBarges: string[]
  hide: () => void
}) {
  const {
    values: { towConfiguration },
    setFieldValue,
  } = useNominationFormContext()

  const preselectedBarges = useMemo(
    () =>
      (barges ?? []).filter(b => pinnedBarges.includes(b.barge.id)).map(({ barge: { id, name } }) => ({ id, name })),
    [barges, pinnedBarges]
  )

  return (
    <div className={styles.pinnedBargesSection}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={hide}>
          <ArrowBack />
        </button>
        <span className={styles.sectionTitle}>Preselected barges</span>
      </div>
      <PreselectedBargesSection
        className={styles.editPreselectedBarges}
        preselectedBarges={preselectedBarges}
        towConfiguration={towConfiguration}
        setTowConfiguration={config => setFieldValue('towConfiguration', config)}
      />
    </div>
  )
}

function BargesStats({
  barges,
  pinnedBarges,
  excludedBarges,
  isFetching,
  isBargePoolVisible,
  isSelectable,
  toggleIsBargePoolVisisble,
  toggleIsPinnedBargesVisible,
}: {
  barges?: OverviewBarge[]
  pinnedBarges: string[]
  excludedBarges: string[]
  isFetching: boolean
  isSelectable: boolean
  isBargePoolVisible: boolean
  toggleIsBargePoolVisisble: () => void
  toggleIsPinnedBargesVisible: () => void
}) {
  const {
    values: { towConfiguration },
  } = useNominationFormContext()

  const { totalNumberOfEmpties, totalNumberOfRakes } = useMemo(
    () =>
      barges === undefined
        ? { totalNumberOfEmpties: undefined, totalNumberOfRakes: undefined }
        : barges.reduce(
            (acc: { totalNumberOfEmpties: number; totalNumberOfRakes: number }, b) => {
              if (isEmpty(b)) acc.totalNumberOfEmpties += 1
              if (b.barge.hullType === HullType.Rake) acc.totalNumberOfRakes += 1
              return acc
            },
            { totalNumberOfEmpties: 0, totalNumberOfRakes: 0 }
          ),
    [barges]
  )

  return (
    <ul
      className={classnames(styles.stats, {
        [styles.isFetching]: isFetching,
        [styles.isDisabled]: barges === undefined,
      })}>
      {isFetching ? <Spinner className={styles.spinner} /> : null}
      <li>
        <button
          className={classnames(styles.bargePoolToggle, { [styles.isActive]: isBargePoolVisible })}
          onClick={toggleIsBargePoolVisisble}>
          <span>
            <TableChart />
            Barge pool
          </span>
        </button>
      </li>
      <li>
        <span className={styles.bigNr}>
          {barges && totalNumberOfEmpties !== undefined ? barges.length - totalNumberOfEmpties : ELLIPSIS}
        </span>
        <span>Total loaded barges</span>
      </li>
      <li>
        <span className={styles.bigNr}>{totalNumberOfEmpties ?? ELLIPSIS}</span>
        <span>Total empty barges</span>
      </li>
      <li>
        <span className={styles.bigNr}>{totalNumberOfRakes ?? ELLIPSIS}</span>
        <span>Total rakes</span>
      </li>
      <li className={styles.pinnedBarges}>
        <span className={styles.count}>
          <span className={styles.bigNr}>{barges ? pinnedBarges.length : ELLIPSIS}</span>
          <span>Total preselected barges</span>
        </span>
        <span className={classnames([styles.actions, styles.pinned])}>
          <button disabled={!isSelectable} onClick={toggleIsBargePoolVisisble}>
            <PushPin />
          </button>
          <button
            disabled={!(isSelectable && pinnedBarges.length && towConfiguration)}
            onClick={toggleIsPinnedBargesVisible}>
            <ViewCompact />
          </button>
        </span>
      </li>
      <li className={styles.pinnedBarges}>
        <span className={styles.count}>
          <span className={styles.bigNr}>{barges ? excludedBarges.length : ELLIPSIS}</span>
          <span>Total excluded barges</span>
        </span>
        <span className={classnames([styles.actions, styles.excluded])}>
          <button disabled={!isSelectable} onClick={toggleIsBargePoolVisisble}>
            <CloseIcon />
          </button>
          <button
            disabled={!(isSelectable && excludedBarges.length && towConfiguration)}
            onClick={toggleIsPinnedBargesVisible}>
            <ViewCompact />
          </button>
        </span>
      </li>
    </ul>
  )
}
