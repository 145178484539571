import { useState, useEffect } from 'react'

import { CombinedError } from 'urql'

import { CreateBargeNominationMutation, GraphqlTripStatus, SavedBargeNominationQuery } from '../../../generated/graphql'
import { useLatestNominationContext } from '../../../providers/LatestNominationProvider'
import { getTimeFromExpectedDeparture, isoZonedDateTime } from '../../../utils/date'


export function useLatestNomination({
  bargeFilters,
  towConfiguration,
  prioritizeHotBarges,
}: NonNullable<SavedBargeNominationQuery['savedBargeNomination']>['nomination']['userRequest']) {
  const [{ data, error, fetching }, setLatestNomination] = useState<{
    data?: CreateBargeNominationMutation
    error?: CombinedError
    fetching: boolean
  }>({
    fetching: true,
  })

  const { getNomination } = useLatestNominationContext()

  useEffect(() => {
    const expectedDepartureTime = getTimeFromExpectedDeparture(bargeFilters.expectedDepartureTime)

    const bargeNomination = {
      lane: bargeFilters.lane,
      origin: bargeFilters.towOrigin,
      destination: bargeFilters.towDestination,
      excludeBargeIds: bargeFilters.excludeBargeIds,
      excludeNominatedBarges: bargeFilters.excludeNominatedBarges,
      excludeBargeTypes: bargeFilters.excludeBargeTypes,
      excludeTripStatuses: bargeFilters.excludeTripStatuses.map(s => s as any as GraphqlTripStatus),
      excludeTboInfoBarges: bargeFilters.excludeTboInfoBarges,
      maximumDraft: bargeFilters.maximumDraft,
      expectedDepartureTime: expectedDepartureTime ? isoZonedDateTime(expectedDepartureTime) : null,
      towConfiguration,
      prioritizeHotBarges,
    }
    setLatestNomination({ fetching: true })
    getNomination(bargeNomination).then(res => {
      setLatestNomination({ ...res, fetching: false })
    })
  }, [getNomination, bargeFilters, prioritizeHotBarges, towConfiguration])

  return { fetching, data, error }
}
