import { SetStateAction, useCallback, useState } from 'react'

import classnames from 'classnames'

import { Filter } from '../../../Domain/BargeFilters'
import { CloseButton } from '../../../ui/CloseButton/CloseButton'

import { ExclusionFilters } from './ExclusionFilters'
import styles from './LaneFilters.module.scss'
import { Column, ColumnEntry, ColumnTitle } from './Layout'
import { LocationFilter } from './LocationFilter'
import { MaxDraftFilter } from './MaxDraftFilter'
import { TimeWindowFilter } from './TimeWindowFilter'

const filterToSearchParams = (filter: Filter) =>
  Object.entries(filter).reduce((params, [key, value]) => {
    if (value) {
      params.set(key, value.toString())
    }

    return params
  }, new URLSearchParams())

type Props = {
  filters: Filter
  setIsVisible: (b: boolean) => void
  readOnly: boolean
  isEnabled: boolean
}

export function LaneFilters({ filters: initialFilters, setIsVisible, readOnly, isEnabled }: Props) {
  const [filters, setFilters] = useState<Filter>(initialFilters)

  const close = useCallback(() => setIsVisible(false), [setIsVisible])

  const handleApply = useCallback(() => {
    const params = filterToSearchParams(filters)

    window.history.pushState({}, '', `${window.location.pathname}?${params}`)
    close()
  }, [filters, close])

  return (
    <div className={styles.panel}>
      <div className={styles.content}>
        <CloseButton handleClose={close} theme={{ button: styles.closeButton }} />
        <h2 className={styles.title}>Barge pool filters</h2>

        <div className={styles.columns}>
          <Filters filters={filters} setFilters={setFilters} readOnly={readOnly} isEnabled={isEnabled} />
        </div>
        <footer>
          <div className={styles.columns}>
            <button onClick={handleApply}>Apply</button>
          </div>
        </footer>
      </div>
    </div>
  )
}

export function Filters({
  filters,
  setFilters,
  readOnly,
  isEnabled = true,
}: {
  filters: Filter
  setFilters: (f: SetStateAction<Filter>) => void
  readOnly: boolean
  isEnabled: boolean
}) {
  const setMaxDraft = useCallback(
    (maxDraft?: number) => {
      setFilters(current => ({ ...current, maxDraft }))
    },
    [setFilters]
  )

  const setTimeWindow = useCallback(
    (time?: number) => {
      setFilters(current => ({ ...current, time }))
    },
    [setFilters]
  )
  return (
    <>
      <Column>
        <ColumnTitle title="Location" />
        <LocationFilter filters={filters} setFilters={setFilters} readOnly={readOnly} />
      </Column>
      <hr />
      <Column>
        <ColumnTitle title="Expected departure time" />
        <TimeWindowFilter
          isDisabled={filters.origin === undefined}
          time={filters.time}
          setTimeWindow={setTimeWindow}
          readOnly={readOnly}
        />
      </Column>
      <hr />
      <Column>
        <ColumnTitle title="Filter" />
        <ColumnEntry>
          <span className={classnames({ [styles.disabledLabel]: readOnly })}>Draft</span>
          <MaxDraftFilter maxDraft={filters.maxDraft} setMaxDraft={setMaxDraft} readOnly={readOnly} />
        </ColumnEntry>
        <ColumnEntry columnClassnames={[classnames({ [styles.disabledLabel]: readOnly })]}>
          Exclude
          <ExclusionFilters filters={filters} setFilters={setFilters} isEnabled={isEnabled} readOnly={readOnly} />
        </ColumnEntry>
      </Column>
    </>
  )
}
